.trustees-modal__head {
	max-width: 1000px;
	width: 90%;
	margin: 0 auto;
	padding: 60px 0 0 0;
}
.trustees-modal__head h1 {
	font-size: 2.25rem;
	color: #6dba68;
}
.trustees-modal__content {
	max-width: 1000px;
	margin: 0 auto;
	width: 90%;
	padding: 23px 0 46px 0;
}
.trustees-modal__list {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% + 50px);
	margin: 0 0 0 -25px;
}

.trustees-modal__item {
	width: 50%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.trustees-modal__item:nth-of-type(4) {
	display: none;
	visibility: hidden;
}
.trustees-modal__item-wrapper {
	padding: 0 25px 37px;
}
.trustees-modal__item-img {
	width: 100%;
	display: block;
}

.trustees-modal__item-name {
	margin: 20px 0 0 0;
	font-size: 1rem;
	line-height: 1.14;
	font-weight: bold;
}
.trustees-modal__item-position {
	margin: 8px 0 0 0;
	font-size: 1rem;
	line-height: 1.14;
	font-weight: bold;
}
.trustees-modal__item-title {
	margin: 8px 0 0 0;
	font-size: 0.875rem;
	line-height: 1.14;
}
.trustees-modal__item-text {
	max-width: 177px;
	margin: 0 auto;
}
@media only screen and (min-width: 768px) {
	.trustees-modal__item {
		width: calc(99.99% / 3);
	}
	.trustees-modal__head h1 {
		font-size: 3rem;
	}
}
@media only screen and (min-width: 992px) {
	.trustees-modal__item {
		width: 25%;
	}
	.trustees-modal__item:nth-of-type(4) {
		display: block;
	}
}
