.modal {
	display: block;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0%;
	left: -100%;
	z-index: 51;
	transition: all 0.4s ease-in-out;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

.modal.open-right {
	left: 100%;
}

.modal.modal-open {
	left: 0;
}

.modal__content {
	position: relative;
	width: 100%;
}

.modal__button {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 30px;
	height: 30px;
	z-index: 6;
	transition: 300ms;
}

.modal__button svg {
	width: 100%;
	height: 100%;
}

.modal__button:hover {
	opacity: 0.7;
}
.modal__block {
	position: relative;
	width: 100%;
	z-index: 5;
}

.modal__content {
	max-width: 800px;
	margin: 60px 0 0;
}
.modal__subheadline {
	font-size: 1.25rem;
	line-height: 1.833;
	text-transform: uppercase;
	font-weight: 900;
	margin: 0;
}
.modal__headline {
	font-size: 1.875rem;
	font-weight: bold;
	text-transform: uppercase;
	margin: 0;
	line-height: 1.1;
}
.modal__body {
	margin: 40px 0 0 0;
	padding: 0 0 30px 0;
}

.modal__footer {
	position: relative;
	height: 350px;
	z-index: 4;
}

.narrative + .modal .modal__footer,
.sl4 + .modal .modal__footer {
	height: 550px;
}

.modal__bucket {
	padding: 0 0 70px 0;
}

.modal__list {
	border: 2px solid #c54634;
	background: rgba(255, 255, 255, 0.5);
	padding: 33px 44px 28px 37px;
}
.modal__list-title {
	font-size: 1.125rem;
	line-height: 1.333;
	text-transform: uppercase;
	color: #d33a29;
	font-size: 500;
}
.modal__list-block {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.modal__list-item {
	position: relative;
	padding: 0 0 0 18px;
}
.modal__list-item:before {
	content: '';
	position: absolute;
	top: 11px;
	left: 0;
	width: 6px;
	height: 6px;
	background: #474747;
	border-radius: 100%;
}
@media (min-width: 768px) {
	.modal__subheadline {
		font-size: 1.5rem;
	}
}
@media (min-width: 992px) {
	.modal {
		overflow: hidden;
	}
	.modal__button {
		top: 39px;
		right: 42px;
		width: 52px;
		height: 52px;
	}

	.modal__headline {
		font-size: 2.5rem;
	}
	.modal__block {
		overflow-y: auto;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
	.modal__content {
		height: 100vh;
		width: 70%;
	}
	.modal__body {
		column-count: 2;
		column-gap: 38px;
		padding: 0 0 70px 0;
	}
	.modal__footer {
		position: absolute;
		bottom: 0;
		right: -7%;
		height: 100%;
	}
	.narrative + .modal .modal__footer,
	.sl4 + .modal .modal__footer {
		height: 100%;
	}
}

@media (min-width: 1200px) {
	.modal__footer {
		right: 0;
	}
}
