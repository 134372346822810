.news-modal {
	background-color: #47474770;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0%;
	left: 0%;
	z-index: 50;
	transition: visibility 0.5s, opacity 0.5s linear;
	overflow-x: hidden;
	overflow-y: auto;
}
.modal-fade-in {
	visibility: visible;
	opacity: 1;
}
.modal-fade-out {
	visibility: hidden;
	opacity: 0;
}

.news-modal__block {
	width: 96%;
	max-width: 1100px;
	margin: 0 auto;
	color: #fff;
	padding: 50px 0;
}

.news-modal__content {
	width: 100%;
	z-index: 2;
	position: relative;
}

.news-modal__content-link {
	color: #fff;
	text-decoration: underline;
	transition: 300ms;
}
.news-modal__content-link:hover {
	opacity: 0.7;
}

.news-modal__bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.news-modal__wrapper {
	position: relative;
	padding: 68px 25px 67px 25px;
}

.news_modal__button {
	position: absolute;
	display: inline-block;
	height: auto;
	top: 25px;
	right: 25px;
	width: 30px;
	height: 30px;
	filter: brightness(65%);
	opacity: 1;
	transition: 300ms;
}
.news_modal__button svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.news_modal__button:hover {
	opacity: 0.7;
}

.news-modal__title {
	font-size: 1.5;
	line-height: 1.1;
	text-align: center;
	text-transform: uppercase;
}
.news-modal__bucket {
	margin: 30px 0 0 0;
}
.news-modal__bucket-img {
	width: 100%;
	max-width: 315px;
	margin: 0 auto;
}

.news-modal__bucket-placeholder {
	width: 100%;
	height: 0;
	padding-top: 92.7%;
	position: relative;
	top: 0;
	left: 0;
}
.news-modal__bucket-message {
	margin: 30px 0 0 0;
}

.svg {
	position: absolute;
	width: 0;
	height: 0;
}
.clipped {
	transform: translateZ(0);
	width: 100%;
	height: 100%;
	background-size: cover;
	-webkit-clip-path: url(#my-clip-path);
	clip-path: url(#my-clip-path);
}

@media only screen and (min-width: 768px) {
	.news-modal__title {
		font-size: 2.5rem;
	}
}

@media only screen and (min-width: 992px) {
	.news-modal__wrapper {
		padding: 68px 51px 67px 70px;
	}
	.news-modal__bucket {
		display: flex;
		flex-direction: row-reverse;
	}
	.news-modal__bucket-img {
		margin: 0;
		width: 33%;
	}
	.news-modal__title {
		text-align: left;
		padding-right: 75px;
	}
	.news-modal__bucket-message {
		flex: 1;
		margin: 0 45px 0 0;
	}

	.news_modal__button {
		width: 52px;
		height: 52px;
		top: 43px;
		right: 42px;
	}
}
