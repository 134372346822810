@import url(https://use.typekit.net/mio2xdd.css);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);

/* Breakpoint references */
/* @media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {} */

html {
	font-size: 16px;
	font-family: 'Source Sans Pro', sans-serif;
	-webkit-text-size-adjust: 100%;
}

body {
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fff;
	padding: 0;
	margin: 0;
	color: #474747;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.headline {
	font-family: 'brother-1816-printed', sans-serif;
	margin: 0 0 25px 0;
}

.headline--1 {
	font-size: 1.5rem;
	line-height: 1.125;
	text-transform: uppercase;
}

a {
	text-decoration: none;
}

p {
	margin: 0;
}

.paragraph-text {
	font-size: 1rem;
	line-height: 1.5;
	margin: 0 0 10px 0;
}
.paragraph-text--italic {
	font-style: italic;
}
.container {
	max-width: 1280px;
	margin: 0 auto;
	width: calc(100% - 50px);
}

.container--fixed-tree {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 9;
	/* position: fixed;
    top: 0;
    right: 50%;
    height: 100%;
    z-index: 9;
    transform: translateX(50%); */
}
.container--modal {
	max-width: 1000px;
}
button {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
}
.jump-link {
	position: relative;
	height: 0;
	width: 0;
	top: -100px;
}

.img-bkg {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	object-position: center center;
}
.img-bkg--contain {
	object-fit: contain;
}

.App {
	overflow-x: hidden;
}

.common-block {
	padding-right: 25px;
	padding-left: 25px;
	position: relative;
}
@media only screen and (min-width: 768px) {
	.common-block {
		max-width: 600px;
		margin: 0 0 0 28%;
		padding-right: 50px;
		padding-left: 50px;
	}
	.paragraph-text {
		font-size: 1.125rem;
	}
}

/* Tree fixed svg image */
.TreeSvg {
	display: block;
	width: auto;
	height: 100vh;
	position: relative;
	top: 0;
	left: -25%;
	z-index: 5;
	/* display: block;
	width: auto;
	height: 100%;
	position: absolute;
	top: 0;
	right: 446px;
	z-index: 5; */
}

@media only screen and (min-width: 1440px) {
	.TreeSvg {
		left: -35%;
	}
}

/* Our main content that lays on top of the tree lives within this element */
.main-layer {
	position: relative;
	z-index: 10;
}

.parallax {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	z-index: 1;
	display: none;
}
.parallax .parallax-inner {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transition: 0ms;
}
.parallax__img {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.parallax__img--lfol {
	width: 142%;
	left: -9%;
	/* max-width: 1820px; */
	/* max-height: 2010px; */
}
.parallax__img--narrative {
	width: 114%;
	left: -11%;
	/* max-width: 1455px; */
}
.parallax__img--sl2 {
	width: 128%;
	left: -30%;
	/* max-width: 1637px; */
}
.parallax__img--sl3 {
	width: 146%;
	left: -29%;
	/* max-width: 1875px; */
}
.parallax__img--sl4 {
	width: 109%;
	left: -2%;
	/* max-width: 1397px; */
}

.parallax__img--sl5 {
	width: 139%;
	left: -16%;
	/* max-width: 1780px; */
}

.parallax__img--news {
	width: 123%;
	left: -6.5%;
	/* max-width: 1573px; */
}

@media only screen and (min-width: 768px) {
	.parallax {
		display: block;
	}
}

.menu {
	position: fixed;
	z-index: 50;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	top: 0;
	right: 0;
}

.HamburgerMenuButton {
	position: fixed;
	top: 0;
	right: 0;
	width: 60px;
	height: 56px;
	background: #4361a3;
	display: flex;
	justify-content: center;
	align-items: center;
}

.HamburgerMenuButton span:before,
.HamburgerMenuButton span:after {
	position: absolute;
	content: '';
}
.HamburgerMenuButton span,
.HamburgerMenuButton span:before,
.HamburgerMenuButton span:after {
	width: 35px;
	height: 5px;
	background-color: #fff;
	display: block;
}
.HamburgerMenuButton span:before {
	margin-top: -12px;
}
.HamburgerMenuButton span:after {
	margin-top: 12px;
}
/* example 5 */
.HamburgerMenuButton span {
	transition-duration: 0s;
	transition-delay: 0.2s;
}
.HamburgerMenuButtonActive span {
	background-color: rgba(0, 0, 0, 0);
	transition-delay: 0.2s;
}
.HamburgerMenuButton span:before {
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;
}
.HamburgerMenuButtonActive span:before {
	margin-top: 0;
	transform: rotate(45deg);
	transition-delay: 0s, 0.2s;
}
.HamburgerMenuButton span:after {
	transition-property: margin, transform;
	transition-duration: 0.2s;
	transition-delay: 0.2s, 0s;
}
.HamburgerMenuButtonActive span:after {
	margin-top: 0;
	transform: rotate(-45deg);
	transition-delay: 0s, 0.2s;
}

.menu .Links {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	transition: all 0.2s ease-in-out;
	transform: translateX(340px);
	position: fixed;
	top: 100px;
}

.menu .ActiveMenu {
	transform: translateX(0);
	height: calc(100% - 100px);
	overflow-y: auto;
}

.menu .Links a {
	height: 58px;
	width: 328px;
	padding: 0;
	margin: 0;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 1.25rem;
	font-weight: bold;
	line-height: 58px;
	transition: all 0.2s ease-in-out;
	font-family: 'brother-1816-printed', sans-serif;
	font-weight: 700;
	font-style: normal;
	text-transform: uppercase;
}

.menu .Links a:first-child {
	background-color: #c0cdeb;
	color: #4361a3;
}
.menu .Links a:nth-child(2) {
	background-color: #f0bab0;
	color: #a35343;
}
.menu .Links a:nth-child(3) {
	background-color: #f7d970;
	color: #dc8b26;
}
.menu .Links a:nth-child(4) {
	background-color: #61b9e1;
	color: #1179ab;
}
.menu .Links a:nth-child(5) {
	background-color: #f39168;
	color: #d33a29;
}
.menu .Links a:nth-child(6) {
	background-color: #bd90c1;
	color: #5e3091;
}
.menu .Links a:nth-child(7) {
	background-color: #62beb0;
	color: #0b7582;
}
.menu .Links a:nth-child(8) {
	background-color: #aee6e6;
	color: #26919f;
}
.menu .Links a:nth-child(9) {
	background-color: #d9ed72;
	color: #5eaf6c;
}
.menu .Links a:nth-child(10) {
	background-color: #c0cdeb;
	color: #4361a3;
}

.menu .Links a:hover {
	width: 341px;
}
.menu .Links a:hover:first-child {
	background-color: #cedaf5;
}
.menu .Links a:hover:nth-child(2) {
	background-color: #f8ccc4;
}
.menu .Links a:hover:nth-child(3) {
	background-color: #ffe383;
}
.menu .Links a:hover:nth-child(4) {
	background-color: #79caef;
}
.menu .Links a:hover:nth-child(5) {
	background-color: #fda37d;
}
.menu .Links a:hover:nth-child(6) {
	background-color: #cda2d1;
}
.menu .Links a:hover:nth-child(7) {
	background-color: #74cec0;
}
.menu .Links a:hover:nth-child(8) {
	background-color: #bbeded;
}
.menu .Links a:hover:nth-child(9) {
	background-color: #deef8b;
}
.menu .Links a:hover:nth-child(10) {
	background-color: #cedaf5;
}

/* For mobile */
@media only screen and (max-width: 480px) {
	.menu .Links a {
		font-size: 1.125rem;
		height: 55px;
	}
	.HamburgerMenuButton {
		width: 42px;
		height: 39px;
	}

	.HamburgerMenuButton span,
	.HamburgerMenuButton span:before,
	.HamburgerMenuButton span:after {
		width: 24px;
		height: 3px;
		background-color: #fff;
		display: block;
	}

	.HamburgerMenuButton span:before {
		margin-top: -8px;
	}
	.HamburgerMenuButton span:after {
		margin-top: 8px;
	}

	.HamburgerMenuButtonActive span {
		background-color: rgba(0, 0, 0, 0);
	}
	.HamburgerMenuButtonActive span:after {
		margin-top: 0;
	}
	.HamburgerMenuButtonActive span:before {
		margin-top: 0;
	}
}
@media only screen and (max-width: 375px) {
	.HamburgerMenuButton {
		margin-bottom: 20px;
	}
	.menu .Links a {
		font-size: 1.25rem;
		height: 45px;
		line-height: 45px;
	}
}

@media only screen and (min-width: 992px) {
	.menu .ActiveMenu {
		height: auto;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(-100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes pulse-in {
	0% {
		background: #1d2a46;
	}
	100% {
		background: #4361a3;
	}
}

@keyframes sway {
	0% {
		transform: rotate(-5deg);
	}
	50% {
		transform: rotate(20deg);
	}
	100% {
		transform: rotate(-5deg);
	}
}
@keyframes sway-seeds {
	0% {
		transform: translateY(-50px) rotate(0deg);
	}
	50% {
		transform: translateY(-50px) rotate(20deg);
	}
	100% {
		transform: translateY(-50px) rotate(0deg);
	}
}

.lfol {
	background: #162035;
	padding: 315px 0 271px 0;
	animation: pulse-in 2000ms ease 800ms 1 normal forwards;
	position: relative;
	overflow: hidden;
}

.lfol__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.lfol__hero-image {
	width: 100%;
	max-width: 399px;
	margin: 0 auto;
}
.lfol__hero-image-file {
	opacity: 0;
	transform: translateY(-100px);
	width: 65%;
	margin: 0 auto;
	height: auto;
	display: block;
	animation: fade-in 2000ms ease 800ms 1 normal forwards;
}
.lfol__hero-subtitle {
	text-align: center;
	color: #fff;
	margin: 22px 0 0 0;
	text-transform: uppercase;
	font-size: 1.375rem;
	font-weight: 600;
}
.lfol__hero-signature {
	margin: 50px auto 0;
	width: 230px;
}
.lfol__hero-signature-file {
	width: 100%;
	height: auto;
	display: block;
}
.lfol__hero-description {
	text-transform: uppercase;
	text-align: center;
	color: #d4e069;
	font-size: 1.25rem;
	font-weight: 600;
	margin: 36px 0 0 0;
}
.lfol__block {
	padding: 39px 47px 45px;
	background: #e3e7f4;
	margin: 117px 0 0 0;
	position: relative;
}
.lfol__block-title {
	color: #4361a3;
	text-transform: uppercase;
}
.lfol__block-title span {
	font-weight: 400;
}
.lfol__block-leaders {
	margin: 45px 0 0 0;
}
.lfol__block-leaders-item {
	margin: 0 0 50px 0;
}
.lfol__block-leaders-item:nth-last-of-type(1) {
	margin: 0;
}
.lfol__block-leaders-image {
	margin: 0 auto;
	display: block;
}
.lfol__block-leaders-name {
	margin: 11px 0 0 0;
	text-align: center;
	font-weight: 700;
}

.lfol__seeds {
	position: absolute;
}

.lfol__seeds--green-left {
	right: calc(100% + 30px);
	top: 55%;
	width: 190px;
	height: 235px;
}
.lfol__seeds--green-right {
	left: calc(100% - 65px);
	top: 30%;
	width: 210px;
	height: 330px;
}
.lfol__seeds--red-right {
	left: calc(100% - 46px);
	bottom: 70px;
	width: 160px;
	height: 330px;
}

.lfol__seeds-img {
	position: absolute;
	width: 55px;
	height: auto;
	opacity: 0;
	transform: translateY(0);
	transition: opacity 400ms, transform 700ms;
}

.lfol__tulips-img {
	position: absolute;
	width: auto;
	height: 125px;
	transform: rotate(-5deg);
	animation: sway 10s 0ms infinite normal ease-in-out;
}
.lfol__tulips-img:nth-of-type(1) {
	top: 0;
	left: 50%;
	animation-delay: 1s;
}
.lfol__tulips-img:nth-of-type(2) {
	top: 20%;
	left: 1%;
	animation-delay: 1500ms;
}
.lfol__tulips-img:nth-of-type(3) {
	top: 40%;
	left: 30%;
	animation-delay: 2500ms;
}
.lfol__tulips-img:nth-of-type(4) {
	top: 60%;
	left: 60%;
	animation-delay: 500ms;
}

.lfol__tulips-img:nth-of-type(5) {
	top: 65%;
	left: 0;
	animation-delay: 2s;
}

.lfol__seeds-img.is-active {
	opacity: 1;
	transform: translateY(-50px);
	animation: sway-seeds 10s 700ms infinite normal ease-in-out;
}

.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(1) {
	top: 0;
	left: 45%;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(2) {
	top: 10%;
	left: calc(100% - 50px);
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(3) {
	top: 19%;
	left: 50%;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(4) {
	top: 43%;
	left: 19%;
	width: 70px;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(5) {
	top: 60%;
	left: 50%;
	width: 70px;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(6) {
	top: 80%;
	left: 12%;
	width: 70px;
}
.lfol__seeds--green-left .lfol__seeds-img:nth-of-type(1) {
	top: 0;
	left: 30%;
	width: 80px;
}
.lfol__seeds--green-left .lfol__seeds-img:nth-of-type(2) {
	top: 45%;
	left: 0;
	width: 80px;
}
.lfol__seeds--green-left .lfol__seeds-img:nth-of-type(3) {
	top: 62%;
	left: calc(100% - 80px);
	width: 80px;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
	.lfol {
		padding: 409px 0 371px 0;
	}
	.lfol__hero {
		max-width: 698px;
		margin: 0 0 0 24%;
	}
	.lfol__hero-image-file {
		width: 100%;
	}

	.lfol__block {
		max-width: 698px;
		margin: 175px 0 0 24%;
	}
}
@media only screen and (min-width: 992px) {
	.lfol {
		padding: 272px 0 400px 0;
	}
	.lfol__block {
		margin: 234px 0 0 24%;
	}
	.lfol__block-leaders {
		display: flex;
	}
	.lfol__block-leaders-item {
		width: 50%;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
@media only screen and (min-width: 1200px) {
	.lfol {
		padding: 272px 0 500px 0;
	}
}

.spotlight-btn {
	font-family: 'brother-1816-printed', sans-serif;
	text-align: left;
	width: auto;
	text-transform: uppercase;
	font-weight: 600;
	width: 100%;
}

.spotlight-btn__container {
	position: relative;
}

.spotlight-btn__container:not(.spotlight-btn__container--left) {
	padding: 150px 0 0 0;
}

.spotlight-btn__image {
	position: relative;
	z-index: 2;
}

.spotlight-btn__image-file {
	position: absolute;
	left: 0;
	bottom: -70px;
	width: 200px;
	height: auto;
	transition: 300ms;
}

.spotlight-btn__block {
	position: relative;
	padding: 85px 75px 35px 35px;
	flex: 1 1;
}
.spotlight-btn__block:before {
	content: '';
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background: #ffffff00;
	z-index: 1;
	transition: 300ms;
}
.spotlight-btn__arrow {
	width: 40px;
	height: auto;
	position: absolute;
	right: 20px;
	bottom: 50%;
	transform: translateY(50%);
	-webkit-transform: translateY(50%);
	z-index: 2;
	transition: 300ms;
}
.spotlight-btn__overline {
	display: block;
	font-size: 1.25rem;
	line-height: 1.1875;
	font-weight: 900;
	position: relative;
	z-index: 2;
}
.spotlight-btn__title {
	display: block;
	font-size: 1.375rem;
	position: relative;
	z-index: 2;
	line-height: 1.1875;
}

.spotlight-btn--left {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
}

.spotlight-btn--left .spotlight-btn__block {
	padding: 35px 35px 85px 75px;
}

.spotlight-btn--left .spotlight-btn__arrow {
	right: auto;
	left: 20px;
	transform: rotate(180deg) translateY(-25px);
}

.spotlight-btn--left .spotlight-btn__image-file {
	bottom: auto;
	left: auto;
	top: -70px;
	right: 0;
}

.spotlight-btn:hover .spotlight-btn__arrow {
	right: 10px;
}

.spotlight-btn--left:hover .spotlight-btn__arrow {
	left: 10px;
}
.spotlight-btn:hover .spotlight-btn__block:before {
	background: #ffffff30;
}

.spotlight-btn:hover .spotlight-btn__image-file {
	transform: scale(1.05) rotate(-5deg);
	-webkit-transform: scale(1.05) rotate(-5deg);
}

.spotlight-btn--left:hover .spotlight-btn__image-file {
	transform: scale(1.05) rotate(5deg);
	-webkit-transform: scale(1.05) rotate(5deg);
}

@media only screen and (min-width: 992px) {
	.spotlight-btn {
		display: flex;
		align-items: center;
	}

	.spotlight-btn__block {
		padding: 35px 75px 35px 90px;
		min-height: 160px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.spotlight-btn__image-file {
		position: absolute;
		left: -20px;
		right: auto;
		top: auto;
		bottom: 50%;
		transform: translateY(50%) scale(1) rotate(0deg);
		-webkit-transform: translateY(50%) scale(1) rotate(0deg);
		width: calc(100% + 35px);
	}

	.spotlight-btn--left {
		flex-direction: row-reverse;
	}
	.spotlight-btn--left .spotlight-btn__block {
		padding: 35px 90px 35px 75px;
	}

	.spotlight-btn--left .spotlight-btn__image-file {
		left: -70px;
		top: auto;
		right: 0;
		bottom: 50%;
		transform: translateY(50%) scale(1);
		-webkit-transform: translateY(50%) scale(1);
	}

	.spotlight-btn__container:not(.spotlight-btn__container--left) {
		padding: 25px 0 0 0;
	}

	.spotlight-btn:hover .spotlight-btn__image-file {
		transform: translateY(50%) scale(1.05) rotate(-5deg);
		-webkit-transform: translateY(50%) scale(1.05) rotate(-5deg);
	}

	.spotlight-btn--left:hover .spotlight-btn__image-file {
		transform: translateY(50%) scale(1.05) rotate(5deg);
		-webkit-transform: translateY(50%) scale(1.05) rotate(5deg);
	}
}

@media only screen and (min-width: 1200px) {
	.spotlight-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 1000px;
	}

	.spotlight-btn__block {
		padding: 52px 130px 44px 63px;
	}

	.spotlight-btn__title {
		font-size: 2rem;
	}

	.spotlight-btn__arrow {
		width: auto;
		right: 47px;
	}
	.spotlight-btn__image-file {
		left: 0;
	}

	.spotlight-btn--left .spotlight-btn__block {
		padding: 52px 44px 44px 145px;
	}

	.spotlight-btn--left .spotlight-btn__arrow {
		right: auto;
		left: 47px;
	}

	.spotlight-btn--left .spotlight-btn__image-file {
		left: auto;
	}

	.spotlight-btn__container:not(.spotlight-btn__container--left) {
		padding: 0;
	}

	.spotlight-btn__arrow {
		transition: 300ms;
	}

	.spotlight-btn:hover .spotlight-btn__arrow {
		right: 30px;
	}

	.spotlight-btn--left:hover .spotlight-btn__arrow {
		left: 30px;
	}
}

.modal {
	display: block;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0%;
	left: -100%;
	z-index: 51;
	transition: all 0.4s ease-in-out;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
}

.modal.open-right {
	left: 100%;
}

.modal.modal-open {
	left: 0;
}

.modal__content {
	position: relative;
	width: 100%;
}

.modal__button {
	position: absolute;
	top: 15px;
	right: 15px;
	width: 30px;
	height: 30px;
	z-index: 6;
	transition: 300ms;
}

.modal__button svg {
	width: 100%;
	height: 100%;
}

.modal__button:hover {
	opacity: 0.7;
}
.modal__block {
	position: relative;
	width: 100%;
	z-index: 5;
}

.modal__content {
	max-width: 800px;
	margin: 60px 0 0;
}
.modal__subheadline {
	font-size: 1.25rem;
	line-height: 1.833;
	text-transform: uppercase;
	font-weight: 900;
	margin: 0;
}
.modal__headline {
	font-size: 1.875rem;
	font-weight: bold;
	text-transform: uppercase;
	margin: 0;
	line-height: 1.1;
}
.modal__body {
	margin: 40px 0 0 0;
	padding: 0 0 30px 0;
}

.modal__footer {
	position: relative;
	height: 350px;
	z-index: 4;
}

.narrative + .modal .modal__footer,
.sl4 + .modal .modal__footer {
	height: 550px;
}

.modal__bucket {
	padding: 0 0 70px 0;
}

.modal__list {
	border: 2px solid #c54634;
	background: rgba(255, 255, 255, 0.5);
	padding: 33px 44px 28px 37px;
}
.modal__list-title {
	font-size: 1.125rem;
	line-height: 1.333;
	text-transform: uppercase;
	color: #d33a29;
	font-size: 500;
}
.modal__list-block {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.modal__list-item {
	position: relative;
	padding: 0 0 0 18px;
}
.modal__list-item:before {
	content: '';
	position: absolute;
	top: 11px;
	left: 0;
	width: 6px;
	height: 6px;
	background: #474747;
	border-radius: 100%;
}
@media (min-width: 768px) {
	.modal__subheadline {
		font-size: 1.5rem;
	}
}
@media (min-width: 992px) {
	.modal {
		overflow: hidden;
	}
	.modal__button {
		top: 39px;
		right: 42px;
		width: 52px;
		height: 52px;
	}

	.modal__headline {
		font-size: 2.5rem;
	}
	.modal__block {
		overflow-y: auto;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
	.modal__content {
		height: 100vh;
		width: 70%;
	}
	.modal__body {
		-webkit-column-count: 2;
		        column-count: 2;
		grid-column-gap: 38px;
		-webkit-column-gap: 38px;
		        column-gap: 38px;
		padding: 0 0 70px 0;
	}
	.modal__footer {
		position: absolute;
		bottom: 0;
		right: -7%;
		height: 100%;
	}
	.narrative + .modal .modal__footer,
	.sl4 + .modal .modal__footer {
		height: 100%;
	}
}

@media (min-width: 1200px) {
	.modal__footer {
		right: 0;
	}
}

@keyframes float-down {
	0% {
		transform: translateY(-400px) translateX(-80px) rotate(65deg);
		opacity: 0;
	}
	25% {
		transform: translateY(-300px) translateX(-40px) rotate(55deg);
	}
	50% {
		transform: translateY(-200px) translateX(0px) rotate(45deg);
		opacity: 1;
	}
	75% {
		transform: translateY(-100px) translateX(40px) rotate(35deg);
	}
	100% {
		transform: translateY(0px) translateX(0) rotate(25deg);
	}
}

.narrative {
	padding: 100px 0 75px 0;
	position: relative;
	background: linear-gradient(#f7d970, #edeee7);
	left: 0;
	top: 0;
}

.narrative__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	top: -50px;
	left: 0;
}

.narrative__top {
	width: 100%;
	height: auto;
	position: absolute;
	bottom: 100%;
	left: 0;
	margin: 0 0 -11% 0;
	z-index: 3;
}
.narrative__top-image {
	width: 100%;
	height: auto;
	display: block;
}

.narrative__block {
	background: linear-gradient(rgba(247, 217, 112, 0.8), rgba(252, 245, 227, 0.8));
	padding-top: 38px;
	padding-bottom: 135px;
}

.narrative__block-leaf {
	position: absolute;
	right: -155px;
	bottom: calc(100% - 110px);
	width: auto;
	height: auto;
	transform: rotate(25deg);
	opacity: 0;
}
.narrative__block-leaf.animate {
	animation: float-down 2000ms linear 0ms 1 normal forwards;
	opacity: 1;
}

.narrative__block-title {
	text-transform: uppercase;
	color: #6a4311;
	font-size: 1.688rem;
}
.narrative__block-message span {
	font-weight: bold;
}

.narrative__block-subtitle {
	text-transform: uppercase;
}

.narrative__block-bucket {
	position: relative;
	font-family: 'brother-1816-printed', sans-serif;
}
.narrative__block-bucket-image {
	position: relative;
	top: 15px;
	left: 13px;
	width: 100%;
	max-width: 290px;
}
.narrative__block-bucket-text {
	background: #fff;
	padding: 30px 33px 30px 34px;
	color: #da8824;
	text-transform: uppercase;
	font-size: 1.125rem;
	line-height: 1.417;
	margin: 0;
}
.narrative__block-bucket-text span {
	font-weight: bold;
}

.image-container {
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
}
.section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
}
.section-img {
	position: absolute;
	z-index: 1;
	right: 70px;
	bottom: 30px;
	max-width: 260px;
}

.section-img-2 {
	position: absolute;
	z-index: 1;
	right: 70px;
	bottom: 350px;
	max-width: 165px;
}

.section-svg-1 {
	position: absolute;
	z-index: 2;
	right: 0px;
	bottom: 225px;
}
.section-svg-2 {
	position: absolute;
	z-index: 2;
	right: 15px;
	bottom: 0px;
}

.narrative__action {
	position: relative;
	margin: 126px 0 0 0;
}
.narrative__petals-left {
	position: absolute;
	top: -50px;
	left: 0;
	width: auto;
	height: auto;
	z-index: 3;
}

.narrative__petals-block {
	width: 265px;
	height: 410px;
	position: absolute;
	top: 0;
	left: 60%;
}
.narrative__petals-img {
	position: absolute;
	height: 30px;
	width: auto;
	display: block;
	opacity: 0;
	transition: 400ms;
}
.narrative__petals-img.is-active {
	opacity: 1;
}

.narrative__petals-img:nth-of-type(1) {
	top: 0;
	left: 60%;
}
.narrative__petals-img:nth-of-type(2) {
	top: 2%;
	left: 80%;
}
.narrative__petals-img:nth-of-type(3) {
	top: 30%;
	left: 80%;
	height: 20px;
}
.narrative__petals-img:nth-of-type(4) {
	top: 51%;
	left: 78%;
}
.narrative__petals-img:nth-of-type(5) {
	top: 51%;
	left: 100%;
}
.narrative__petals-img:nth-of-type(6) {
	top: 61%;
	left: 82%;
}
.narrative__petals-img:nth-of-type(7) {
	top: 70%;
	left: 72%;
}
.narrative__petals-img:nth-of-type(8) {
	top: 80%;
	left: 90%;
}
.narrative__petals-img:nth-of-type(9) {
	top: 82%;
	left: 75%;
}
.narrative__petals-img:nth-of-type(10) {
	top: 77%;
	left: 59%;
}
.narrative__petals-img:nth-of-type(11) {
	top: 80%;
	left: 49%;
}
.narrative__petals-img:nth-of-type(12) {
	top: 79%;
	left: 37%;
}
.narrative__petals-img:nth-of-type(13) {
	top: 88%;
	left: 45%;
}
.narrative__petals-img:nth-of-type(14) {
	top: 85%;
	left: 0%;
}
.narrative__petals-img:nth-of-type(15) {
	top: 100%;
	left: 33%;
}

@media only screen and (min-width: 992px) {
	.narrative {
		padding: 100px 0 217px;
	}
	.narrative__background {
		top: -75px;
	}
	.narrative__block-title {
		font-size: 2.25rem;
	}

	.narrative__block-bucket-text {
		font-size: 1.5rem;
	}
	.narrative__block-feature {
		display: flex;
		margin: 0 0 105px 0;
	}

	.narrative__petals-block {
		left: calc(100% - 30px);
		top: -62px;
	}
}
@media only screen and (min-width: 1200px) {
	.narrative {
		padding: 100px 0 317px 0;
	}
	.narrative__background {
		top: -140px;
	}
	.narrative__block-content {
		display: flex;
		align-items: flex-end;
	}
	.narrative__block-column {
		width: 50%;
	}
	.narrative__block-column--left {
		min-width: 340px;
		margin: 0 50px 0 0;
	}
	.narrative__block-bucket-text {
		width: 263px;
	}
}

.sl2 {
	position: relative;
	padding: 0 0 75px 0;
	background: linear-gradient(#edeee7 10%, #cce2f5 70%, #e8e4e3 100%);
}

.sl2__background {
	width: auto;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.sl2__block {
	padding-top: 55px;
	padding-bottom: 144px;
	background: linear-gradient(rgba(237, 238, 231, 0.8), rgba(204, 226, 245, 0.8));
	max-width: 700px;
	padding-right: 30px;
}

.sl2__block-title {
	color: #1179ab;
}
.sl2__block-row {
	padding: 28px 0 94px;
}

.sl2__block-image {
	display: block;
	width: 100%;
	height: auto;
	margin: 30px 0;
}
.sl2-section-bg {
	position: absolute;
	right: -175px;
	bottom: -175px;
}
.sl2-section-img {
	position: absolute;
	z-index: 1;
	right: 95px;
	bottom: 40px;
	max-width: 260px;
}

.sl2__action {
	position: relative;
}
.sl2__dots {
	position: absolute;
	top: 20px;
	left: 46%;
	transform: rotate(-30deg);
}

.sl2__block-bucket {
	background: #fff;
	font-size: 1.125rem;
	line-height: 1.417;
	color: #1179ab;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl2__block-bucket-text {
	padding: 30px 33px 25px 34px;
}
.sl2__block-bucket-text span {
	font-weight: bold;
}

@media only screen and (min-width: 992px) {
	.sl2__block {
		padding-bottom: 70px;
	}
	.sl2__block-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.sl2__block-bucket {
		width: calc(48% - 11px);
		max-width: 325px;
		font-size: 1.5rem;
	}
	.sl2__block-image {
		width: calc(52% - 11px);
		height: auto;
		display: block;
		margin: 0;
		max-width: 350px;
	}
}

@media only screen and (min-width: 1200px) {
	.sl2 {
		padding: 0 0 262px 0;
	}
	.sl2__block {
		padding-bottom: 144px;
	}
}

@keyframes sway-flower-1 {
	0% {
		transform: translate(31.347px, 0) rotate(-5deg);
	}
	50% {
		transform: translate(31.347px, 0) rotate(15deg);
	}
	100% {
		transform: translate(31.347px, 0) rotate(-5deg);
	}
}
@keyframes sway-flower-2 {
	0% {
		transform: translate(0, 38.831px) rotate(8deg);
	}
	50% {
		transform: translate(0, 38.831px) rotate(20deg);
	}
	100% {
		transform: translate(0, 38.831px) rotate(9deg);
	}
}
@keyframes sway-flower-3 {
	0% {
		transform: translate(29.689px, 123.241px) rotate(-5deg);
	}
	50% {
		transform: translate(29.689px, 123.241px) rotate(-15deg);
	}
	100% {
		transform: translate(29.689px, 123.241px) rotate(-5deg);
	}
}

.sl3 {
	padding: 0 0 75px 0;
	position: relative;
	background: linear-gradient(#e8e4e3, #f6e3e1);
}

.sl3__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: 75px;
	left: 0;
}

.sl3__block {
	padding-top: 52px;
	padding-bottom: 70px;
	background: linear-gradient(rgba(235, 228, 224, 0.8), rgba(250, 230, 216, 0.8));
}
.sl3__block-content {
	margin: 0 0 100px 0;
}

.sl3__block-title {
	color: #a81f25;
}

.sl3-section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}
.sl3-section-img {
	position: absolute;
	z-index: 1;
	right: 88px;
	bottom: 40px;
	max-width: 260px;
}

.sl3__action {
	position: relative;
}
.sl3__flowers {
	position: absolute;
	bottom: -35px;
	right: -46px;
	width: auto;
	height: auto;
	z-index: 3;
	overflow: visible;
}
.sl3__flowers-icon-1 {
	animation: sway-flower-1 10s 0ms infinite normal ease-in-out;
}
.sl3__flowers-icon-2 {
	animation: sway-flower-2 10s 0ms infinite normal ease-in-out;
}
.sl3__flowers-icon-3 {
	animation: sway-flower-3 10s 0ms infinite normal ease-in-out;
}

.sl3__block-bucket {
	position: relative;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl3__block-bucket-image {
	margin: 30px auto;
	width: 100%;
	max-width: 400px;
	display: block;
}
.sl3__block-bucket-text {
	background: #fff;
	padding: 30px 40px 30px 34px;
	color: #d33a29;
	text-transform: uppercase;
	font-size: 1.125rem;
	margin: 0;
	line-height: 1.5;
}
.sl3__block-bucket-text span {
	font-weight: bold;
}
@media only screen and (min-width: 480px) {
	.sl3__block-bucket-image {
		min-width: 325px;
	}
}
@media only screen and (min-width: 992px) {
	.sl3__block-bucket-text {
		font-size: 1.25rem;
	}
	.sl3-section-img {
		bottom: 80px;
	}
}

@media only screen and (min-width: 1200px) {
	.sl3 {
		padding: 0 0 214px 0;
	}
	.sl3__block {
		padding-bottom: 140px;
	}

	.sl3__block-bucket-text {
		width: 250px;
	}
	.sl3__block-content {
		display: flex;
		align-items: flex-end;
	}
	.sl3__block-column {
		width: 50%;
	}
	.sl3__block-column--left {
		min-width: 360px;
		margin: 0 50px 0 0;
	}
	.sl3__block-bucket-image {
		margin: 0 0 53px 0;
	}
}

.sl4 {
	background: linear-gradient(#f6e3e1 30%, #f3e0ed 70%, #e4efef) 100%;
	position: relative;
}

.sl4__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: -20%;
	left: 0;
}

.sl4__block {
	padding-top: 47px;
	padding-bottom: 30px;
	background: linear-gradient(rgba(246, 226, 226, 0.8), rgba(244, 225, 232, 0.8));
}

.sl4__block-btn {
	padding-top: 57px;
	padding-bottom: 160px;
	position: relative;
}

.sl4__single-flower {
	position: absolute;
	z-index: 3;
	top: 63%;
	right: 30%;
	width: auto;
	height: auto;
}
.sl4__flowers {
	position: absolute;
	top: -20px;
	right: -5%;
	width: auto;
	height: auto;
	overflow: visible;
}

.sl4__flowers-icon {
	opacity: 0;
	transition: 1s ease-in-out;
}
.sl4__flowers-icon.is-visible {
	opacity: 1;
}

.sl4-section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
	max-width: 910px;
}
.sl4-section-svg {
	position: absolute;
	right: -80px;
	bottom: -80px;
	z-index: 1;
}
.sl4-section-img {
	position: absolute;
	z-index: 2;
	right: 20px;
	bottom: 310px;
	max-width: 245px;
}

.sl4-section-img-2 {
	position: absolute;
	z-index: 2;
	right: 42px;
	bottom: 60px;
	max-width: 275px;
}

.sl4__block-bucket {
	position: relative;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl4__block-bucket-image {
	position: relative;
	margin: 15px 0 -15px 0;
	left: 13px;
	width: 100%;
	max-width: 324px;
}
.sl4__block-bucket-text {
	background: #fff;
	padding: 30px 40px 30px 34px;
	color: #5e3091;
	text-transform: uppercase;
	font-size: 1.125rem;
	margin: 0;
	line-height: 1.5;
}
.sl4__block-bucket-text span {
	font-weight: bold;
}

@media only screen and (min-width: 992px) {
	.sl4__block-btn {
		padding-top: 83px;
		padding-bottom: 99px;
	}

	.sl4__single-flower {
		top: 52%;
		right: 32%;
	}
	.sl4__flowers {
		top: -20px;
		right: -5%;
	}

	.sl4__block-bucket-text {
		font-size: 1.25rem;
	}
}
@media only screen and (min-width: 1200px) {
	.sl4__block-btn {
		padding-bottom: 420px;
	}

	.sl4__single-flower {
		top: 52%;
		right: 102px;
	}
	.sl4__flowers {
		top: 400px;
		right: 130%;
		transition: 5s ease-in-out;
		transform: rotate(20deg);
	}

	.sl4__flowers.animate {
		top: -67px;
		right: -21.5%;
		transform: rotate(0deg);
	}

	.sl4__block-bucket-text {
		width: 250px;
	}
	.sl4__block-content {
		display: flex;
		align-items: flex-start;
	}
	.sl4__block-column {
		width: 50%;
	}
	.sl4__block-column--left {
		min-width: 360px;
		margin: 0 50px 0 0;
	}
	.sl4__block-bucket-image {
		margin: 0 0 -15px 0;
	}
}

.sl5 {
	background: linear-gradient(#e4efef, #e1f3f0);
	padding-bottom: 75px;
	position: relative;
}

.sl5__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: -8%;
	left: 0;
}

.sl5__block-title {
	color: #087482;
}

.sl5__block {
	padding-top: 51px;
	background: linear-gradient(rgba(225, 242, 239, 0.8), rgba(223, 241, 229, 0.8));
	z-index: 2;
	position: relative;
	max-width: 680px;
}

.sl5__block-bottom {
	padding-top: 40px;
	padding-bottom: 33px;
	background: linear-gradient(rgba(223, 241, 229, 0.8), rgba(221, 240, 240, 0.8));
	z-index: 1;
	position: relative;
	max-width: 680px;
}

.sl5__action {
	position: relative;
	margin: 50px 0 0 0;
}

.sl5__leafs {
	position: absolute;
	bottom: 30%;
	left: 80%;
	width: auto;
	height: auto;
	z-index: 3;
}

.sl5__petals {
	position: absolute;
	bottom: 53%;
	left: 28%;
	width: auto;
	height: auto;
}

.sl5-section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
}
.sl5-section-svg {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}
.sl5-section-img {
	position: absolute;
	z-index: 1;
	right: 90px;
	bottom: 25px;
	max-width: 300px;
}

.sl5__block-bucket {
	position: relative;
	z-index: 2;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl5__block-bucket-image {
	position: relative;
	bottom: 25px;
	width: 100%;
	display: block;
	max-width: 265px;
}
.sl5__block-bucket-text {
	background: #fff;
	padding: 30px 40px 30px 34px;
	color: #469592;
	text-transform: uppercase;
	font-size: 1.125rem;
	margin: 0;
	line-height: 1.5;
}
.sl5__block-bucket-text span {
	font-weight: bold;
}

@media only screen and (min-width: 992px) {
	.sl5__block-bucket-text {
		font-size: 1.25rem;
	}
}

@media only screen and (min-width: 1200px) {
	.sl5 {
		padding-bottom: 181px;
	}
	.sl5__block {
		padding-bottom: 117px;
	}

	.sl5__block-bottom {
		padding-top: 174px;
	}
	.sl5__block-message--top {
		margin: 0 0 75px 0;
	}

	.sl5__block .spotlight-btn {
		left: -192px;
	}

	.sl5__block .spotlight-btn__block {
		min-height: 122px;
	}

	.sl5__leafs {
		bottom: -40px;
		left: 100%;
	}
	.sl5__petals {
		bottom: calc(100% - 60px);
		left: 28%;
	}
	.sl5__block-bucket-text {
		width: 216px;
	}
	.sl5__block-content {
		display: flex;
		align-items: flex-start;
	}

	.sl5__block-column--left {
		width: 43%;
	}
	.sl5__block-column--right {
		min-width: 360px;
		margin: 0 0 0 40px;
		width: 57%;
	}
}

.news-modal {
	background-color: #47474770;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0%;
	left: 0%;
	z-index: 50;
	transition: visibility 0.5s, opacity 0.5s linear;
	overflow-x: hidden;
	overflow-y: auto;
}
.modal-fade-in {
	visibility: visible;
	opacity: 1;
}
.modal-fade-out {
	visibility: hidden;
	opacity: 0;
}

.news-modal__block {
	width: 96%;
	max-width: 1100px;
	margin: 0 auto;
	color: #fff;
	padding: 50px 0;
}

.news-modal__content {
	width: 100%;
	z-index: 2;
	position: relative;
}

.news-modal__content-link {
	color: #fff;
	text-decoration: underline;
	transition: 300ms;
}
.news-modal__content-link:hover {
	opacity: 0.7;
}

.news-modal__bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.news-modal__wrapper {
	position: relative;
	padding: 68px 25px 67px 25px;
}

.news_modal__button {
	position: absolute;
	display: inline-block;
	height: auto;
	top: 25px;
	right: 25px;
	width: 30px;
	height: 30px;
	-webkit-filter: brightness(65%);
	        filter: brightness(65%);
	opacity: 1;
	transition: 300ms;
}
.news_modal__button svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.news_modal__button:hover {
	opacity: 0.7;
}

.news-modal__title {
	font-size: 1.5;
	line-height: 1.1;
	text-align: center;
	text-transform: uppercase;
}
.news-modal__bucket {
	margin: 30px 0 0 0;
}
.news-modal__bucket-img {
	width: 100%;
	max-width: 315px;
	margin: 0 auto;
}

.news-modal__bucket-placeholder {
	width: 100%;
	height: 0;
	padding-top: 92.7%;
	position: relative;
	top: 0;
	left: 0;
}
.news-modal__bucket-message {
	margin: 30px 0 0 0;
}

.svg {
	position: absolute;
	width: 0;
	height: 0;
}
.clipped {
	transform: translateZ(0);
	width: 100%;
	height: 100%;
	background-size: cover;
	-webkit-clip-path: url(#my-clip-path);
	clip-path: url(#my-clip-path);
}

@media only screen and (min-width: 768px) {
	.news-modal__title {
		font-size: 2.5rem;
	}
}

@media only screen and (min-width: 992px) {
	.news-modal__wrapper {
		padding: 68px 51px 67px 70px;
	}
	.news-modal__bucket {
		display: flex;
		flex-direction: row-reverse;
	}
	.news-modal__bucket-img {
		margin: 0;
		width: 33%;
	}
	.news-modal__title {
		text-align: left;
		padding-right: 75px;
	}
	.news-modal__bucket-message {
		flex: 1 1;
		margin: 0 45px 0 0;
	}

	.news_modal__button {
		width: 52px;
		height: 52px;
		top: 43px;
		right: 42px;
	}
}

.news {
	background: linear-gradient(#e1f3f0, #6681b4);
	padding: 0 0 75px 0;
	position: relative;
}

.news__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
}

.news__content-title {
	text-transform: uppercase;
	color: #057b6f;
	font-size: 3rem;
	margin: 0;
	text-align: center;
	margin: 0 0 75px 0;
}

.news__content-list {
	display: flex;
	flex-wrap: wrap;
	max-width: 1055px;
	width: 100%;
	margin: 0 auto;
	justify-content: space-around;
}
.news__content-item {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: 0 0 80px 0;
}

.news__content-btn {
	display: block;
	width: 100%;
	position: relative;
}
.news__content-label {
	text-transform: uppercase;
	color: #fff;
	font-size: 1.5rem;
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	-webkit-filter: brightness(100%);
	        filter: brightness(100%);
	transition: 300ms;
	bottom: -50px;
	right: 0;
	font-family: 'brother-1816-printed', sans-serif;
	min-height: 90px;
}

.news__content-btn-wrapper {
	width: 100%;
	height: 0;
	position: relative;
	padding-top: 100%;
	background: #a7a7a7;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	transition: 300ms;
}

.news__content-label-bkg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.news__content-svg {
	position: absolute;
	width: 0;
	height: 0;
}
.news__content-clipped {
	width: 100%;
	height: 100%;
	background-size: cover;
}
.news__content-clipped--1 {
	-webkit-clip-path: url(#news-clip-path-1);
	clip-path: url(#news-clip-path-1);
}
.news__content-clipped--2 {
	-webkit-clip-path: url(#news-clip-path-2);
	clip-path: url(#news-clip-path-2);
}
.news__content-clipped--3 {
	-webkit-clip-path: url(#news-clip-path-3);
	clip-path: url(#news-clip-path-3);
}
.news__content-clipped--4 {
	-webkit-clip-path: url(#news-clip-path-4);
	clip-path: url(#news-clip-path-4);
}
.news__content-clipped--5 {
	-webkit-clip-path: url(#news-clip-path-5);
	clip-path: url(#news-clip-path-5);
}

.news__content-label-text {
	z-index: 2;
	position: relative;
	padding: 15px;
}

@media only screen and (min-width: 768px) {
	.news__content-item {
		width: 47%;
	}

	.news__content-title {
		font-size: 3.1875rem;
	}
}
@media only screen and (min-width: 992px) {
	.news {
		padding: 0 0 120px 0;
	}

	.news__content-list {
		width: 85%;
	}
	.news__content-item {
		margin: 0;
	}

	.news__content-item:nth-of-type(1) {
		width: calc(99.99% / (3 / 2));
	}
	.news__content-item:nth-of-type(2) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(3) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(4) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(5) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(1) .news__content-btn {
		margin: 0 39px 0 20px;
	}
	.news__content-item:nth-of-type(2) .news__content-btn {
		margin: 0 60px 0 39px;
	}
	.news__content-item:nth-of-type(3) .news__content-btn {
		margin: 109px 33px 0 0;
	}
	.news__content-item:nth-of-type(4) .news__content-btn {
		margin: 57px 34px 0 33px;
	}
	.news__content-item:nth-of-type(5) .news__content-btn {
		margin: 151px 0 0 34px;
	}

	.news__content-item:nth-of-type(1) .news__content-btn-wrapper {
		padding-top: 46%;
	}
	.news__content-item:nth-of-type(2) .news__content-btn-wrapper {
		padding-top: 113%;
	}
	.news__content-item:nth-of-type(3) .news__content-btn-wrapper {
		padding-top: 85%;
	}
	.news__content-item:nth-of-type(4) .news__content-btn-wrapper {
		padding-top: 87%;
	}
	.news__content-item:nth-of-type(5) .news__content-btn-wrapper {
		padding-top: 80%;
	}

	.news__content-btn:hover .news__content-btn-wrapper {
		transform: scale(1.1);
	}
	.news__content-btn:hover .news__content-label {
		-webkit-filter: brightness(90%);
		        filter: brightness(90%);
	}

	.news__content-label {
		width: auto;
		padding: 0 20px;
	}

	.news__content-item:nth-of-type(2) .news__content-label {
		width: 330px;
	}

	.news__content-label.bottom-right {
		bottom: -53px;
		right: -46px;
		top: auto;
		left: auto;
	}
	.news__content-label.bottom-left {
		bottom: -62px;
		left: -35px;
		top: auto;
		right: auto;
	}
	.news__content-label.top-left {
		top: -52px;
		left: -34px;
		bottom: auto;
		right: auto;
		width: 350px;
	}
	.news__content-label.top-right {
		top: -60px;
		right: -41px;
		left: auto;
		bottom: auto;
		width: 300px;
	}
	.news__content-title {
		margin: 0 0 50px 0;
	}
}

@media only screen and (min-width: 1200px) {
	.news__content-title {
		font-size: 4.25rem;
	}
	.news {
		padding: 0 0 220px 0;
	}
}

.staff-modal {
	background-color: #fff;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0%;
	left: 0%;
	z-index: 50;
	transition: visibility 0.5s, opacity 0.5s linear;
}
.staff-modal-container {
	position: relative;
	width: 100%;
	height: auto;
}
.staff-modal .modal-scroll {
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	margin: 0px;
	justify-content: space-between;
	align-content: flex-end;
	align-items: center;
}
.staff-modal .close-button {
	position: absolute;
	display: inline-block;
	width: 30px;
	height: 30px;
	top: 15px;
	right: 15px;
	transition: 300ms;
}
.staff-modal .close-button svg {
	width: 100%;
	height: 100%;
}
.staff-modal .close-button:hover {
	opacity: 0.7;
}
.modal-top-bg {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	z-index: -1;
}
.staff-modal .modal-head {
	padding: 60px 0 20px;
	z-index: 1;
	margin: 0 auto;
	max-width: 1000px;
	width: 90%;
}
.staff-modal .modal-head h1 {
	font-size: 2.5rem;
	font-weight: bold;
	text-transform: uppercase;
	color: #6dba68;
}

.staff-modal .modal-body {
	height: auto;
	flex-direction: column;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 200;
	align-items: stretch;
	line-height: 30px;
	z-index: 1;
	align-content: stretch;
	margin: 0 auto 250px;
	max-width: 1000px;
	width: 90%;
}

.list-item {
	text-align: left;
	margin: 0 0 25px 0;
}
.list-item__name {
	margin: 0;
	font-size: 1rem;
	line-height: 1;
	font-weight: bold;
}
.list-item__title {
	margin: 10px 0px 0;
	font-size: 0.875rem;
	line-height: 1.143;
	font-weight: 400;
}
.staff-footer-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	position: relative;
}
.staff-footer-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}
.staff-footer-container .person-img {
	padding-right: 40px;
}
.staff-footer-container p {
	display: flex;
	flex-direction: column;
	width: 55%;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	color: #fff;
}
.staff-footer-container p strong {
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 10px;
}
.staff-footer-container .footer-icon-png {
	position: absolute;
	right: -60px;
	top: -340px;
	height: auto;
	width: auto;
}
.modal-bottom-bg {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
}

@media screen and (max-width: 1024px) {
	.staff-modal-bg {
		width: 100%;
		height: 1000px;
	}
	.staff-modal-bg-img {
		object-fit: fill;
	}
}

@media screen and (min-width: 480px) {
	.staff-footer-container p {
		font-size: 1.125rem;
	}
	.list-container {
		padding: 0 0 70px 0;
	}
}

@media screen and (min-width: 768px) {
	.list-container {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		align-content: center;
		justify-content: flex-start;
		width: calc(100% + 48px);
		margin: 0 0 0 -24px;
	}
	.list-column {
		width: calc(99.99% / 3);
		z-index: 2;
	}
	.list-item {
		padding: 0 24px;
	}
	.staff-modal .modal-body {
		margin: 0 auto 180px;
	}
}
@media screen and (min-width: 992px) {
	.staff-modal .close-button {
		width: 52px;
		height: 52px;
		top: 39px;
		right: 42px;
	}

	.list-container {
		padding: 0;
	}
}

@media screen and (min-width: 992px) {
	.modal-bottom-bg {
		width: 100%;
	}
	.staff-modal .modal-body {
		margin: 0 auto 90px;
	}
}

@media screen and (min-width: 1200px) {
	.staff-footer-body {
		margin-bottom: 100px;
	}
}

@media screen and (max-width: 768px) {
	.staff-modal .modal-body {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.staff-modal .thumbnail {
		align-self: center;
	}
}
@media screen and (max-width: 480px) {
	.staff-modal .modal-scroll {
		margin: 0 0px;
	}
	.staff-modal .modal-head {
		padding: 60px 0 20px;
	}

	.staff-modal .modal-head h1 {
		font-size: 1.875rem;
	}
	.staff-modal .modal-body {
		flex-direction: column;
		padding: 0px 20px;
		line-height: 22px;
	}
	.staff-modal-bg-img,
	.thumbnail {
		display: none;
	}
	.staff-modal-bg {
		background-color: #6dcab9;
	}
	.staff-footer-body {
		flex-direction: column;
	}

	.staff-footer-container p {
		width: 75%;
	}
	.staff-footer-container .footer-icon-png {
		width: 280px;
		top: -200px;
	}
}

.trustees-modal__head {
	max-width: 1000px;
	width: 90%;
	margin: 0 auto;
	padding: 60px 0 0 0;
}
.trustees-modal__head h1 {
	font-size: 2.25rem;
	color: #6dba68;
}
.trustees-modal__content {
	max-width: 1000px;
	margin: 0 auto;
	width: 90%;
	padding: 23px 0 46px 0;
}
.trustees-modal__list {
	display: flex;
	flex-wrap: wrap;
	width: calc(100% + 50px);
	margin: 0 0 0 -25px;
}

.trustees-modal__item {
	width: 50%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.trustees-modal__item:nth-of-type(4) {
	display: none;
	visibility: hidden;
}
.trustees-modal__item-wrapper {
	padding: 0 25px 37px;
}
.trustees-modal__item-img {
	width: 100%;
	display: block;
}

.trustees-modal__item-name {
	margin: 20px 0 0 0;
	font-size: 1rem;
	line-height: 1.14;
	font-weight: bold;
}
.trustees-modal__item-position {
	margin: 8px 0 0 0;
	font-size: 1rem;
	line-height: 1.14;
	font-weight: bold;
}
.trustees-modal__item-title {
	margin: 8px 0 0 0;
	font-size: 0.875rem;
	line-height: 1.14;
}
.trustees-modal__item-text {
	max-width: 177px;
	margin: 0 auto;
}
@media only screen and (min-width: 768px) {
	.trustees-modal__item {
		width: calc(99.99% / 3);
	}
	.trustees-modal__head h1 {
		font-size: 3rem;
	}
}
@media only screen and (min-width: 992px) {
	.trustees-modal__item {
		width: 25%;
	}
	.trustees-modal__item:nth-of-type(4) {
		display: block;
	}
}

.leaders {
	background: linear-gradient(#e1f3f0, #e1f3f0);
	padding: 50px 0 75px 0;
	position: relative;
}

.leaders__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
}

.leaders .content-container {
	margin: auto;
	background-color: #fff;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	position: relative;
	padding: 25px;
}

.leaders__flowers {
	position: absolute;
	width: auto;
	height: auto;
	top: -144px;
	right: -74px;
}

.leaders .content-container h1 {
	font-size: 2.25rem;
	font-weight: bold;
	color: #6dba68;
}
.leaders .content-container > .leaders-buttons-container {
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	justify-content: center;
	flex-wrap: wrap;
}
.leaders .content-container .leaders-buttons-container button {
	background-color: #91c94f;
	color: #fff;
	font-size: 1rem;
	width: 150px;
	height: 40px;
	text-align: center;
	margin: 20px 15px;
	transition: all 0.2s ease-in-out;
	font-weight: 600;
}

.leaders .content-container .leaders-buttons-container button:hover {
	background-color: #6bbd69;
}

@media only screen and (min-width: 768px) {
	.leaders .content-container {
		width: 70%;
		min-height: 291px;
		padding: 0 25px;
	}
	.leaders .content-container h1 {
		font-size: 3rem;
	}
}

@media only screen and (min-width: 1200px) {
	.leaders {
		padding: 0 0 177px 0;
	}
}

.downloads {
	background: linear-gradient(#6681b4, #4260a2);
	padding: 50px 0 130px 0;
}
.downloads .container {
	text-align: center;
	max-width: 1100px;
}

.downloads__block {
	padding: 50px 50px 65px 50px;
	background-color: #718dc99c;
}

.downloads .container h1 {
	font-size: 1.75rem;
	color: #fff;
	margin-top: 0;
}

.books-container a {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: #fff;
	margin: 30px auto 0;
	max-width: 240px;
}
.books-container a img {
	border: 1px solid #707070;
	box-shadow: #00000052 0px 3px 6px;
	transition: transform 0.3s;
	width: 100%;
	height: auto;
}
.books-container a img:hover {
	transform: scale(1.1);
}
.books-container a span {
	margin-top: 30px;
}
.books-container a span svg {
	margin-right: 10px;
}
@media only screen and (min-width: 768px) {
	.downloads .container h1 {
		font-size: 2.25rem;
	}
	.books-container {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-content: space-between;
		flex-wrap: wrap;
	}
}
@media only screen and (min-width: 992px) {
	.downloads .container h1 {
		font-size: 3rem;
	}
}

.footer {
	background-color: #2e2925;
	padding: 45px 0;
	position: relative;
	z-index: 9;
	padding: 15px 0px;
}

.footer-logo {
	margin: 0 auto 25px;
}

.footer-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: 'Source Sans Pro', sans-serif;
	max-width: 1093px;
	margin: 0 auto;
	justify-content: center;
	flex-wrap: wrap;
}
.footer-container h6 {
	color: #a7a7a7;
	font-size: 0.813rem;
	font-weight: 300;
	margin: 0 auto 25px;
	text-align: center;
}
.footer-container .links-container {
	display: flex;
	align-content: center;
	justify-content: space-around;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.footer-container .links-container a {
	border: 2px solid #d33c29;
	width: 155px;
	padding: 10px 0px;
	text-align: center;
	color: #fff;
	font-size: 0.875rem;
	font-weight: 400;
	transition: all 0.2s ease-in-out;
	margin: 10px;
}

.footer-container .links-container a:hover {
	background-color: #d33c29;
}

@media screen and (min-width: 480px) {
	.footer-container .links-container a {
		margin: 0;
	}
	.footer-container .links-container a:nth-of-type(1) {
		margin: 0 16px 0 0;
	}
}

@media screen and (min-width: 768px) {
	.footer {
		padding: 45px 0;
	}
}

@media screen and (min-width: 992px) {
	.footer-container {
		justify-content: space-evenly;
	}
	.footer-container h6 {
		margin: 0;
	}
	.footer-logo {
		margin: 0;
	}
}

@media screen and (min-width: 1200px) {
	.footer-container h6 {
		margin: 0 90px;
	}
}

.scroll_buttons_container {
	display: flex;
	flex-direction: column;
	align-content: center;
	height: 150px;
	width: 70px;
}
.scroll_buttons_container .btnAnim {
	margin: 10px 0;
	position: absolute;
	right: 20px;
}
.pagenav-btn {
	display: block;
	width: 28px;
	height: 16px;
	position: relative;
	transition: all 0.3s;
}
.pagenav-btn.h-0 {
	height: 0;
	-webkit-clip-path: circle(0);
	clip-path: circle(0);
}
.pagenav-btn.is-hidden {
	display: none;
}
.pagenav-btn + .pagenav-btn {
	margin-top: 12px;
}
.pagenav-btn + .pagenav-btn.h-0 {
	margin-top: 0;
}
.pagenav-svg {
	width: 28px;
	height: 16px;
}
.pagenav-svg--top {
	height: 25px;
}
.pagenav-svg path {
	fill: none;
	stroke-width: 4px;
	stroke: #010205;
	stroke-opacity: 0.5;
	transition: stroke 0.6s, d 0.6s;
}
.pagenav-svg:hover path {
	transition: stroke 0.3s, d 0.6s;
}
@media (min-width: 768px) {
	.pagenav-svg path {
		stroke: #fff;
		stroke-opacity: 1;
	}
	.pagenav-svg:hover path {
		stroke: #00444c;
	}
}

.scroll-container {
	position: fixed;
	bottom: 0.5rem;
	right: 0.5rem;
	cursor: pointer;
	z-index: 49;
}

