.menu {
	position: fixed;
	z-index: 50;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	top: 0;
	right: 0;
}

.HamburgerMenuButton {
	position: fixed;
	top: 0;
	right: 0;
	width: 60px;
	height: 56px;
	background: #4361a3;
	display: flex;
	justify-content: center;
	align-items: center;
}

.HamburgerMenuButton span:before,
.HamburgerMenuButton span:after {
	position: absolute;
	content: '';
}
.HamburgerMenuButton span,
.HamburgerMenuButton span:before,
.HamburgerMenuButton span:after {
	width: 35px;
	height: 5px;
	background-color: #fff;
	display: block;
}
.HamburgerMenuButton span:before {
	margin-top: -12px;
}
.HamburgerMenuButton span:after {
	margin-top: 12px;
}
/* example 5 */
.HamburgerMenuButton span {
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.HamburgerMenuButtonActive span {
	background-color: rgba(0, 0, 0, 0);
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.HamburgerMenuButton span:before {
	-webkit-transition-property: margin, -webkit-transform;
	transition-property: margin, transform;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-delay: 0.2s, 0s;
	transition-delay: 0.2s, 0s;
}
.HamburgerMenuButtonActive span:before {
	margin-top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition-delay: 0s, 0.2s;
	transition-delay: 0s, 0.2s;
}
.HamburgerMenuButton span:after {
	-webkit-transition-property: margin, -webkit-transform;
	transition-property: margin, transform;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
	-webkit-transition-delay: 0.2s, 0s;
	transition-delay: 0.2s, 0s;
}
.HamburgerMenuButtonActive span:after {
	margin-top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transition-delay: 0s, 0.2s;
	transition-delay: 0s, 0.2s;
}

.menu .Links {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	transition: all 0.2s ease-in-out;
	transform: translateX(340px);
	position: fixed;
	top: 100px;
}

.menu .ActiveMenu {
	transform: translateX(0);
	height: calc(100% - 100px);
	overflow-y: auto;
}

.menu .Links a {
	height: 58px;
	width: 328px;
	padding: 0;
	margin: 0;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 1.25rem;
	font-weight: bold;
	line-height: 58px;
	transition: all 0.2s ease-in-out;
	font-family: 'brother-1816-printed', sans-serif;
	font-weight: 700;
	font-style: normal;
	text-transform: uppercase;
}

.menu .Links a:first-child {
	background-color: #c0cdeb;
	color: #4361a3;
}
.menu .Links a:nth-child(2) {
	background-color: #f0bab0;
	color: #a35343;
}
.menu .Links a:nth-child(3) {
	background-color: #f7d970;
	color: #dc8b26;
}
.menu .Links a:nth-child(4) {
	background-color: #61b9e1;
	color: #1179ab;
}
.menu .Links a:nth-child(5) {
	background-color: #f39168;
	color: #d33a29;
}
.menu .Links a:nth-child(6) {
	background-color: #bd90c1;
	color: #5e3091;
}
.menu .Links a:nth-child(7) {
	background-color: #62beb0;
	color: #0b7582;
}
.menu .Links a:nth-child(8) {
	background-color: #aee6e6;
	color: #26919f;
}
.menu .Links a:nth-child(9) {
	background-color: #d9ed72;
	color: #5eaf6c;
}
.menu .Links a:nth-child(10) {
	background-color: #c0cdeb;
	color: #4361a3;
}

.menu .Links a:hover {
	width: 341px;
}
.menu .Links a:hover:first-child {
	background-color: #cedaf5;
}
.menu .Links a:hover:nth-child(2) {
	background-color: #f8ccc4;
}
.menu .Links a:hover:nth-child(3) {
	background-color: #ffe383;
}
.menu .Links a:hover:nth-child(4) {
	background-color: #79caef;
}
.menu .Links a:hover:nth-child(5) {
	background-color: #fda37d;
}
.menu .Links a:hover:nth-child(6) {
	background-color: #cda2d1;
}
.menu .Links a:hover:nth-child(7) {
	background-color: #74cec0;
}
.menu .Links a:hover:nth-child(8) {
	background-color: #bbeded;
}
.menu .Links a:hover:nth-child(9) {
	background-color: #deef8b;
}
.menu .Links a:hover:nth-child(10) {
	background-color: #cedaf5;
}

/* For mobile */
@media only screen and (max-width: 480px) {
	.menu .Links a {
		font-size: 1.125rem;
		height: 55px;
	}
	.HamburgerMenuButton {
		width: 42px;
		height: 39px;
	}

	.HamburgerMenuButton span,
	.HamburgerMenuButton span:before,
	.HamburgerMenuButton span:after {
		width: 24px;
		height: 3px;
		background-color: #fff;
		display: block;
	}

	.HamburgerMenuButton span:before {
		margin-top: -8px;
	}
	.HamburgerMenuButton span:after {
		margin-top: 8px;
	}

	.HamburgerMenuButtonActive span {
		background-color: rgba(0, 0, 0, 0);
	}
	.HamburgerMenuButtonActive span:after {
		margin-top: 0;
	}
	.HamburgerMenuButtonActive span:before {
		margin-top: 0;
	}
}
@media only screen and (max-width: 375px) {
	.HamburgerMenuButton {
		margin-bottom: 20px;
	}
	.menu .Links a {
		font-size: 1.25rem;
		height: 45px;
		line-height: 45px;
	}
}

@media only screen and (min-width: 992px) {
	.menu .ActiveMenu {
		height: auto;
	}
}
