@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(-100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes pulse-in {
	0% {
		background: #1d2a46;
	}
	100% {
		background: #4361a3;
	}
}

@keyframes sway {
	0% {
		transform: rotate(-5deg);
	}
	50% {
		transform: rotate(20deg);
	}
	100% {
		transform: rotate(-5deg);
	}
}
@keyframes sway-seeds {
	0% {
		transform: translateY(-50px) rotate(0deg);
	}
	50% {
		transform: translateY(-50px) rotate(20deg);
	}
	100% {
		transform: translateY(-50px) rotate(0deg);
	}
}

.lfol {
	background: #162035;
	padding: 315px 0 271px 0;
	animation: pulse-in 2000ms ease 800ms 1 normal forwards;
	position: relative;
	overflow: hidden;
}

.lfol__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.lfol__hero-image {
	width: 100%;
	max-width: 399px;
	margin: 0 auto;
}
.lfol__hero-image-file {
	opacity: 0;
	transform: translateY(-100px);
	width: 65%;
	margin: 0 auto;
	height: auto;
	display: block;
	animation: fade-in 2000ms ease 800ms 1 normal forwards;
}
.lfol__hero-subtitle {
	text-align: center;
	color: #fff;
	margin: 22px 0 0 0;
	text-transform: uppercase;
	font-size: 1.375rem;
	font-weight: 600;
}
.lfol__hero-signature {
	margin: 50px auto 0;
	width: 230px;
}
.lfol__hero-signature-file {
	width: 100%;
	height: auto;
	display: block;
}
.lfol__hero-description {
	text-transform: uppercase;
	text-align: center;
	color: #d4e069;
	font-size: 1.25rem;
	font-weight: 600;
	margin: 36px 0 0 0;
}
.lfol__block {
	padding: 39px 47px 45px;
	background: #e3e7f4;
	margin: 117px 0 0 0;
	position: relative;
}
.lfol__block-title {
	color: #4361a3;
	text-transform: uppercase;
}
.lfol__block-title span {
	font-weight: 400;
}
.lfol__block-leaders {
	margin: 45px 0 0 0;
}
.lfol__block-leaders-item {
	margin: 0 0 50px 0;
}
.lfol__block-leaders-item:nth-last-of-type(1) {
	margin: 0;
}
.lfol__block-leaders-image {
	margin: 0 auto;
	display: block;
}
.lfol__block-leaders-name {
	margin: 11px 0 0 0;
	text-align: center;
	font-weight: 700;
}

.lfol__seeds {
	position: absolute;
}

.lfol__seeds--green-left {
	right: calc(100% + 30px);
	top: 55%;
	width: 190px;
	height: 235px;
}
.lfol__seeds--green-right {
	left: calc(100% - 65px);
	top: 30%;
	width: 210px;
	height: 330px;
}
.lfol__seeds--red-right {
	left: calc(100% - 46px);
	bottom: 70px;
	width: 160px;
	height: 330px;
}

.lfol__seeds-img {
	position: absolute;
	width: 55px;
	height: auto;
	opacity: 0;
	transform: translateY(0);
	transition: opacity 400ms, transform 700ms;
}

.lfol__tulips-img {
	position: absolute;
	width: auto;
	height: 125px;
	transform: rotate(-5deg);
	animation: sway 10s 0ms infinite normal ease-in-out;
}
.lfol__tulips-img:nth-of-type(1) {
	top: 0;
	left: 50%;
	animation-delay: 1s;
}
.lfol__tulips-img:nth-of-type(2) {
	top: 20%;
	left: 1%;
	animation-delay: 1500ms;
}
.lfol__tulips-img:nth-of-type(3) {
	top: 40%;
	left: 30%;
	animation-delay: 2500ms;
}
.lfol__tulips-img:nth-of-type(4) {
	top: 60%;
	left: 60%;
	animation-delay: 500ms;
}

.lfol__tulips-img:nth-of-type(5) {
	top: 65%;
	left: 0;
	animation-delay: 2s;
}

.lfol__seeds-img.is-active {
	opacity: 1;
	transform: translateY(-50px);
	animation: sway-seeds 10s 700ms infinite normal ease-in-out;
}

.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(1) {
	top: 0;
	left: 45%;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(2) {
	top: 10%;
	left: calc(100% - 50px);
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(3) {
	top: 19%;
	left: 50%;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(4) {
	top: 43%;
	left: 19%;
	width: 70px;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(5) {
	top: 60%;
	left: 50%;
	width: 70px;
}
.lfol__seeds--green-right .lfol__seeds-img:nth-of-type(6) {
	top: 80%;
	left: 12%;
	width: 70px;
}
.lfol__seeds--green-left .lfol__seeds-img:nth-of-type(1) {
	top: 0;
	left: 30%;
	width: 80px;
}
.lfol__seeds--green-left .lfol__seeds-img:nth-of-type(2) {
	top: 45%;
	left: 0;
	width: 80px;
}
.lfol__seeds--green-left .lfol__seeds-img:nth-of-type(3) {
	top: 62%;
	left: calc(100% - 80px);
	width: 80px;
}

@media only screen and (min-width: 480px) {
}
@media only screen and (min-width: 768px) {
	.lfol {
		padding: 409px 0 371px 0;
	}
	.lfol__hero {
		max-width: 698px;
		margin: 0 0 0 24%;
	}
	.lfol__hero-image-file {
		width: 100%;
	}

	.lfol__block {
		max-width: 698px;
		margin: 175px 0 0 24%;
	}
}
@media only screen and (min-width: 992px) {
	.lfol {
		padding: 272px 0 400px 0;
	}
	.lfol__block {
		margin: 234px 0 0 24%;
	}
	.lfol__block-leaders {
		display: flex;
	}
	.lfol__block-leaders-item {
		width: 50%;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
@media only screen and (min-width: 1200px) {
	.lfol {
		padding: 272px 0 500px 0;
	}
}
