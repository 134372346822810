.spotlight-btn {
	font-family: 'brother-1816-printed', sans-serif;
	text-align: left;
	width: auto;
	text-transform: uppercase;
	font-weight: 600;
	width: 100%;
}

.spotlight-btn__container {
	position: relative;
}

.spotlight-btn__container:not(.spotlight-btn__container--left) {
	padding: 150px 0 0 0;
}

.spotlight-btn__image {
	position: relative;
	z-index: 2;
}

.spotlight-btn__image-file {
	position: absolute;
	left: 0;
	bottom: -70px;
	width: 200px;
	height: auto;
	transition: 300ms;
}

.spotlight-btn__block {
	position: relative;
	padding: 85px 75px 35px 35px;
	flex: 1;
}
.spotlight-btn__block:before {
	content: '';
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	background: #ffffff00;
	z-index: 1;
	transition: 300ms;
}
.spotlight-btn__arrow {
	width: 40px;
	height: auto;
	position: absolute;
	right: 20px;
	bottom: 50%;
	transform: translateY(50%);
	-webkit-transform: translateY(50%);
	z-index: 2;
	transition: 300ms;
}
.spotlight-btn__overline {
	display: block;
	font-size: 1.25rem;
	line-height: 1.1875;
	font-weight: 900;
	position: relative;
	z-index: 2;
}
.spotlight-btn__title {
	display: block;
	font-size: 1.375rem;
	position: relative;
	z-index: 2;
	line-height: 1.1875;
}

.spotlight-btn--left {
	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
}

.spotlight-btn--left .spotlight-btn__block {
	padding: 35px 35px 85px 75px;
}

.spotlight-btn--left .spotlight-btn__arrow {
	right: auto;
	left: 20px;
	transform: rotate(180deg) translateY(-25px);
}

.spotlight-btn--left .spotlight-btn__image-file {
	bottom: auto;
	left: auto;
	top: -70px;
	right: 0;
}

.spotlight-btn:hover .spotlight-btn__arrow {
	right: 10px;
}

.spotlight-btn--left:hover .spotlight-btn__arrow {
	left: 10px;
}
.spotlight-btn:hover .spotlight-btn__block:before {
	background: #ffffff30;
}

.spotlight-btn:hover .spotlight-btn__image-file {
	transform: scale(1.05) rotate(-5deg);
	-webkit-transform: scale(1.05) rotate(-5deg);
}

.spotlight-btn--left:hover .spotlight-btn__image-file {
	transform: scale(1.05) rotate(5deg);
	-webkit-transform: scale(1.05) rotate(5deg);
}

@media only screen and (min-width: 992px) {
	.spotlight-btn {
		display: flex;
		align-items: center;
	}

	.spotlight-btn__block {
		padding: 35px 75px 35px 90px;
		min-height: 160px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.spotlight-btn__image-file {
		position: absolute;
		left: -20px;
		right: auto;
		top: auto;
		bottom: 50%;
		transform: translateY(50%) scale(1) rotate(0deg);
		-webkit-transform: translateY(50%) scale(1) rotate(0deg);
		width: calc(100% + 35px);
	}

	.spotlight-btn--left {
		flex-direction: row-reverse;
	}
	.spotlight-btn--left .spotlight-btn__block {
		padding: 35px 90px 35px 75px;
	}

	.spotlight-btn--left .spotlight-btn__image-file {
		left: -70px;
		top: auto;
		right: 0;
		bottom: 50%;
		transform: translateY(50%) scale(1);
		-webkit-transform: translateY(50%) scale(1);
	}

	.spotlight-btn__container:not(.spotlight-btn__container--left) {
		padding: 25px 0 0 0;
	}

	.spotlight-btn:hover .spotlight-btn__image-file {
		transform: translateY(50%) scale(1.05) rotate(-5deg);
		-webkit-transform: translateY(50%) scale(1.05) rotate(-5deg);
	}

	.spotlight-btn--left:hover .spotlight-btn__image-file {
		transform: translateY(50%) scale(1.05) rotate(5deg);
		-webkit-transform: translateY(50%) scale(1.05) rotate(5deg);
	}
}

@media only screen and (min-width: 1200px) {
	.spotlight-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 1000px;
	}

	.spotlight-btn__block {
		padding: 52px 130px 44px 63px;
	}

	.spotlight-btn__title {
		font-size: 2rem;
	}

	.spotlight-btn__arrow {
		width: auto;
		right: 47px;
	}
	.spotlight-btn__image-file {
		left: 0;
	}

	.spotlight-btn--left .spotlight-btn__block {
		padding: 52px 44px 44px 145px;
	}

	.spotlight-btn--left .spotlight-btn__arrow {
		right: auto;
		left: 47px;
	}

	.spotlight-btn--left .spotlight-btn__image-file {
		left: auto;
	}

	.spotlight-btn__container:not(.spotlight-btn__container--left) {
		padding: 0;
	}

	.spotlight-btn__arrow {
		transition: 300ms;
	}

	.spotlight-btn:hover .spotlight-btn__arrow {
		right: 30px;
	}

	.spotlight-btn--left:hover .spotlight-btn__arrow {
		left: 30px;
	}
}
