.sl2 {
	position: relative;
	padding: 0 0 75px 0;
	background: linear-gradient(#edeee7 10%, #cce2f5 70%, #e8e4e3 100%);
}

.sl2__background {
	width: auto;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.sl2__block {
	padding-top: 55px;
	padding-bottom: 144px;
	background: linear-gradient(rgba(237, 238, 231, 0.8), rgba(204, 226, 245, 0.8));
	max-width: 700px;
	padding-right: 30px;
}

.sl2__block-title {
	color: #1179ab;
}
.sl2__block-row {
	padding: 28px 0 94px;
}

.sl2__block-image {
	display: block;
	width: 100%;
	height: auto;
	margin: 30px 0;
}
.sl2-section-bg {
	position: absolute;
	right: -175px;
	bottom: -175px;
}
.sl2-section-img {
	position: absolute;
	z-index: 1;
	right: 95px;
	bottom: 40px;
	max-width: 260px;
}

.sl2__action {
	position: relative;
}
.sl2__dots {
	position: absolute;
	top: 20px;
	left: 46%;
	transform: rotate(-30deg);
}

.sl2__block-bucket {
	background: #fff;
	font-size: 1.125rem;
	line-height: 1.417;
	color: #1179ab;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl2__block-bucket-text {
	padding: 30px 33px 25px 34px;
}
.sl2__block-bucket-text span {
	font-weight: bold;
}

@media only screen and (min-width: 992px) {
	.sl2__block {
		padding-bottom: 70px;
	}
	.sl2__block-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.sl2__block-bucket {
		width: calc(48% - 11px);
		max-width: 325px;
		font-size: 1.5rem;
	}
	.sl2__block-image {
		width: calc(52% - 11px);
		height: auto;
		display: block;
		margin: 0;
		max-width: 350px;
	}
}

@media only screen and (min-width: 1200px) {
	.sl2 {
		padding: 0 0 262px 0;
	}
	.sl2__block {
		padding-bottom: 144px;
	}
}
