.staff-modal {
	background-color: #fff;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0%;
	left: 0%;
	z-index: 50;
	transition: visibility 0.5s, opacity 0.5s linear;
}
.staff-modal-container {
	position: relative;
	width: 100%;
	height: auto;
}
.staff-modal .modal-scroll {
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	margin: 0px;
	justify-content: space-between;
	align-content: flex-end;
	align-items: center;
}
.staff-modal .close-button {
	position: absolute;
	display: inline-block;
	width: 30px;
	height: 30px;
	top: 15px;
	right: 15px;
	transition: 300ms;
}
.staff-modal .close-button svg {
	width: 100%;
	height: 100%;
}
.staff-modal .close-button:hover {
	opacity: 0.7;
}
.modal-top-bg {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	z-index: -1;
}
.staff-modal .modal-head {
	padding: 60px 0 20px;
	z-index: 1;
	margin: 0 auto;
	max-width: 1000px;
	width: 90%;
}
.staff-modal .modal-head h1 {
	font-size: 2.5rem;
	font-weight: bold;
	text-transform: uppercase;
	color: #6dba68;
}

.staff-modal .modal-body {
	height: auto;
	flex-direction: column;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 200;
	align-items: stretch;
	line-height: 30px;
	z-index: 1;
	align-content: stretch;
	margin: 0 auto 250px;
	max-width: 1000px;
	width: 90%;
}

.list-item {
	text-align: left;
	margin: 0 0 25px 0;
}
.list-item__name {
	margin: 0;
	font-size: 1rem;
	line-height: 1;
	font-weight: bold;
}
.list-item__title {
	margin: 10px 0px 0;
	font-size: 0.875rem;
	line-height: 1.143;
	font-weight: 400;
}
.staff-footer-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	position: relative;
}
.staff-footer-body {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}
.staff-footer-container .person-img {
	padding-right: 40px;
}
.staff-footer-container p {
	display: flex;
	flex-direction: column;
	width: 55%;
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	color: #fff;
}
.staff-footer-container p strong {
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 10px;
}
.staff-footer-container .footer-icon-png {
	position: absolute;
	right: -60px;
	top: -340px;
	height: auto;
	width: auto;
}
.modal-bottom-bg {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
}

@media screen and (max-width: 1024px) {
	.staff-modal-bg {
		width: 100%;
		height: 1000px;
	}
	.staff-modal-bg-img {
		-o-object-fit: fill;
		object-fit: fill;
	}
}

@media screen and (min-width: 480px) {
	.staff-footer-container p {
		font-size: 1.125rem;
	}
	.list-container {
		padding: 0 0 70px 0;
	}
}

@media screen and (min-width: 768px) {
	.list-container {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		align-content: center;
		justify-content: flex-start;
		width: calc(100% + 48px);
		margin: 0 0 0 -24px;
	}
	.list-column {
		width: calc(99.99% / 3);
		z-index: 2;
	}
	.list-item {
		padding: 0 24px;
	}
	.staff-modal .modal-body {
		margin: 0 auto 180px;
	}
}
@media screen and (min-width: 992px) {
	.staff-modal .close-button {
		width: 52px;
		height: 52px;
		top: 39px;
		right: 42px;
	}

	.list-container {
		padding: 0;
	}
}

@media screen and (min-width: 992px) {
	.modal-bottom-bg {
		width: 100%;
	}
	.staff-modal .modal-body {
		margin: 0 auto 90px;
	}
}

@media screen and (min-width: 1200px) {
	.staff-footer-body {
		margin-bottom: 100px;
	}
}

@media screen and (max-width: 768px) {
	.staff-modal .modal-body {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.staff-modal .thumbnail {
		align-self: center;
	}
}
@media screen and (max-width: 480px) {
	.staff-modal .modal-scroll {
		margin: 0 0px;
	}
	.staff-modal .modal-head {
		padding: 60px 0 20px;
	}

	.staff-modal .modal-head h1 {
		font-size: 1.875rem;
	}
	.staff-modal .modal-body {
		flex-direction: column;
		padding: 0px 20px;
		line-height: 22px;
	}
	.staff-modal-bg-img,
	.thumbnail {
		display: none;
	}
	.staff-modal-bg {
		background-color: #6dcab9;
	}
	.staff-footer-body {
		flex-direction: column;
	}

	.staff-footer-container p {
		width: 75%;
	}
	.staff-footer-container .footer-icon-png {
		width: 280px;
		top: -200px;
	}
}
