.news {
	background: linear-gradient(#e1f3f0, #6681b4);
	padding: 0 0 75px 0;
	position: relative;
}

.news__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
}

.news__content-title {
	text-transform: uppercase;
	color: #057b6f;
	font-size: 3rem;
	margin: 0;
	text-align: center;
	margin: 0 0 75px 0;
}

.news__content-list {
	display: flex;
	flex-wrap: wrap;
	max-width: 1055px;
	width: 100%;
	margin: 0 auto;
	justify-content: space-around;
}
.news__content-item {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: 0 0 80px 0;
}

.news__content-btn {
	display: block;
	width: 100%;
	position: relative;
}
.news__content-label {
	text-transform: uppercase;
	color: #fff;
	font-size: 1.5rem;
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	filter: brightness(100%);
	transition: 300ms;
	bottom: -50px;
	right: 0;
	font-family: 'brother-1816-printed', sans-serif;
	min-height: 90px;
}

.news__content-btn-wrapper {
	width: 100%;
	height: 0;
	position: relative;
	padding-top: 100%;
	background: #a7a7a7;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	transition: 300ms;
}

.news__content-label-bkg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.news__content-svg {
	position: absolute;
	width: 0;
	height: 0;
}
.news__content-clipped {
	width: 100%;
	height: 100%;
	background-size: cover;
}
.news__content-clipped--1 {
	-webkit-clip-path: url(#news-clip-path-1);
	clip-path: url(#news-clip-path-1);
}
.news__content-clipped--2 {
	-webkit-clip-path: url(#news-clip-path-2);
	clip-path: url(#news-clip-path-2);
}
.news__content-clipped--3 {
	-webkit-clip-path: url(#news-clip-path-3);
	clip-path: url(#news-clip-path-3);
}
.news__content-clipped--4 {
	-webkit-clip-path: url(#news-clip-path-4);
	clip-path: url(#news-clip-path-4);
}
.news__content-clipped--5 {
	-webkit-clip-path: url(#news-clip-path-5);
	clip-path: url(#news-clip-path-5);
}

.news__content-label-text {
	z-index: 2;
	position: relative;
	padding: 15px;
}

@media only screen and (min-width: 768px) {
	.news__content-item {
		width: 47%;
	}

	.news__content-title {
		font-size: 3.1875rem;
	}
}
@media only screen and (min-width: 992px) {
	.news {
		padding: 0 0 120px 0;
	}

	.news__content-list {
		width: 85%;
	}
	.news__content-item {
		margin: 0;
	}

	.news__content-item:nth-of-type(1) {
		width: calc(99.99% / (3 / 2));
	}
	.news__content-item:nth-of-type(2) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(3) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(4) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(5) {
		width: calc(99.99% / 3);
	}
	.news__content-item:nth-of-type(1) .news__content-btn {
		margin: 0 39px 0 20px;
	}
	.news__content-item:nth-of-type(2) .news__content-btn {
		margin: 0 60px 0 39px;
	}
	.news__content-item:nth-of-type(3) .news__content-btn {
		margin: 109px 33px 0 0;
	}
	.news__content-item:nth-of-type(4) .news__content-btn {
		margin: 57px 34px 0 33px;
	}
	.news__content-item:nth-of-type(5) .news__content-btn {
		margin: 151px 0 0 34px;
	}

	.news__content-item:nth-of-type(1) .news__content-btn-wrapper {
		padding-top: 46%;
	}
	.news__content-item:nth-of-type(2) .news__content-btn-wrapper {
		padding-top: 113%;
	}
	.news__content-item:nth-of-type(3) .news__content-btn-wrapper {
		padding-top: 85%;
	}
	.news__content-item:nth-of-type(4) .news__content-btn-wrapper {
		padding-top: 87%;
	}
	.news__content-item:nth-of-type(5) .news__content-btn-wrapper {
		padding-top: 80%;
	}

	.news__content-btn:hover .news__content-btn-wrapper {
		transform: scale(1.1);
	}
	.news__content-btn:hover .news__content-label {
		filter: brightness(90%);
	}

	.news__content-label {
		width: auto;
		padding: 0 20px;
	}

	.news__content-item:nth-of-type(2) .news__content-label {
		width: 330px;
	}

	.news__content-label.bottom-right {
		bottom: -53px;
		right: -46px;
		top: auto;
		left: auto;
	}
	.news__content-label.bottom-left {
		bottom: -62px;
		left: -35px;
		top: auto;
		right: auto;
	}
	.news__content-label.top-left {
		top: -52px;
		left: -34px;
		bottom: auto;
		right: auto;
		width: 350px;
	}
	.news__content-label.top-right {
		top: -60px;
		right: -41px;
		left: auto;
		bottom: auto;
		width: 300px;
	}
	.news__content-title {
		margin: 0 0 50px 0;
	}
}

@media only screen and (min-width: 1200px) {
	.news__content-title {
		font-size: 4.25rem;
	}
	.news {
		padding: 0 0 220px 0;
	}
}
