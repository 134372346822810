.scroll_buttons_container {
	display: flex;
	flex-direction: column;
	align-content: center;
	height: 150px;
	width: 70px;
}
.scroll_buttons_container .btnAnim {
	margin: 10px 0;
	position: absolute;
	right: 20px;
}
.pagenav-btn {
	display: block;
	width: 28px;
	height: 16px;
	position: relative;
	transition: all 0.3s;
}
.pagenav-btn.h-0 {
	height: 0;
	-webkit-clip-path: circle(0);
	clip-path: circle(0);
}
.pagenav-btn.is-hidden {
	display: none;
}
.pagenav-btn + .pagenav-btn {
	margin-top: 12px;
}
.pagenav-btn + .pagenav-btn.h-0 {
	margin-top: 0;
}
.pagenav-svg {
	width: 28px;
	height: 16px;
}
.pagenav-svg--top {
	height: 25px;
}
.pagenav-svg path {
	fill: none;
	stroke-width: 4px;
	stroke: #010205;
	stroke-opacity: 0.5;
	transition: stroke 0.6s, d 0.6s;
}
.pagenav-svg:hover path {
	transition: stroke 0.3s, d 0.6s;
}
@media (min-width: 768px) {
	.pagenav-svg path {
		stroke: #fff;
		stroke-opacity: 1;
	}
	.pagenav-svg:hover path {
		stroke: #00444c;
	}
}
