.sl5 {
	background: linear-gradient(#e4efef, #e1f3f0);
	padding-bottom: 75px;
	position: relative;
}

.sl5__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: -8%;
	left: 0;
}

.sl5__block-title {
	color: #087482;
}

.sl5__block {
	padding-top: 51px;
	background: linear-gradient(rgba(225, 242, 239, 0.8), rgba(223, 241, 229, 0.8));
	z-index: 2;
	position: relative;
	max-width: 680px;
}

.sl5__block-bottom {
	padding-top: 40px;
	padding-bottom: 33px;
	background: linear-gradient(rgba(223, 241, 229, 0.8), rgba(221, 240, 240, 0.8));
	z-index: 1;
	position: relative;
	max-width: 680px;
}

.sl5__action {
	position: relative;
	margin: 50px 0 0 0;
}

.sl5__leafs {
	position: absolute;
	bottom: 30%;
	left: 80%;
	width: auto;
	height: auto;
	z-index: 3;
}

.sl5__petals {
	position: absolute;
	bottom: 53%;
	left: 28%;
	width: auto;
	height: auto;
}

.sl5-section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
}
.sl5-section-svg {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}
.sl5-section-img {
	position: absolute;
	z-index: 1;
	right: 90px;
	bottom: 25px;
	max-width: 300px;
}

.sl5__block-bucket {
	position: relative;
	z-index: 2;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl5__block-bucket-image {
	position: relative;
	bottom: 25px;
	width: 100%;
	display: block;
	max-width: 265px;
}
.sl5__block-bucket-text {
	background: #fff;
	padding: 30px 40px 30px 34px;
	color: #469592;
	text-transform: uppercase;
	font-size: 1.125rem;
	margin: 0;
	line-height: 1.5;
}
.sl5__block-bucket-text span {
	font-weight: bold;
}

@media only screen and (min-width: 992px) {
	.sl5__block-bucket-text {
		font-size: 1.25rem;
	}
}

@media only screen and (min-width: 1200px) {
	.sl5 {
		padding-bottom: 181px;
	}
	.sl5__block {
		padding-bottom: 117px;
	}

	.sl5__block-bottom {
		padding-top: 174px;
	}
	.sl5__block-message--top {
		margin: 0 0 75px 0;
	}

	.sl5__block .spotlight-btn {
		left: -192px;
	}

	.sl5__block .spotlight-btn__block {
		min-height: 122px;
	}

	.sl5__leafs {
		bottom: -40px;
		left: 100%;
	}
	.sl5__petals {
		bottom: calc(100% - 60px);
		left: 28%;
	}
	.sl5__block-bucket-text {
		width: 216px;
	}
	.sl5__block-content {
		display: flex;
		align-items: flex-start;
	}

	.sl5__block-column--left {
		width: 43%;
	}
	.sl5__block-column--right {
		min-width: 360px;
		margin: 0 0 0 40px;
		width: 57%;
	}
}
