.downloads {
	background: linear-gradient(#6681b4, #4260a2);
	padding: 50px 0 130px 0;
}
.downloads .container {
	text-align: center;
	max-width: 1100px;
}

.downloads__block {
	padding: 50px 50px 65px 50px;
	background-color: #718dc99c;
}

.downloads .container h1 {
	font-size: 1.75rem;
	color: #fff;
	margin-top: 0;
}

.books-container a {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	font-size: 1rem;
	font-weight: bold;
	color: #fff;
	margin: 30px auto 0;
	max-width: 240px;
}
.books-container a img {
	border: 1px solid #707070;
	box-shadow: #00000052 0px 3px 6px;
	transition: transform 0.3s;
	width: 100%;
	height: auto;
}
.books-container a img:hover {
	transform: scale(1.1);
}
.books-container a span {
	margin-top: 30px;
}
.books-container a span svg {
	margin-right: 10px;
}
@media only screen and (min-width: 768px) {
	.downloads .container h1 {
		font-size: 2.25rem;
	}
	.books-container {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-content: space-between;
		flex-wrap: wrap;
	}
}
@media only screen and (min-width: 992px) {
	.downloads .container h1 {
		font-size: 3rem;
	}
}
