.leaders {
	background: linear-gradient(#e1f3f0, #e1f3f0);
	padding: 50px 0 75px 0;
	position: relative;
}

.leaders__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
}

.leaders .content-container {
	margin: auto;
	background-color: #fff;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	position: relative;
	padding: 25px;
}

.leaders__flowers {
	position: absolute;
	width: auto;
	height: auto;
	top: -144px;
	right: -74px;
}

.leaders .content-container h1 {
	font-size: 2.25rem;
	font-weight: bold;
	color: #6dba68;
}
.leaders .content-container > .leaders-buttons-container {
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	justify-content: center;
	flex-wrap: wrap;
}
.leaders .content-container .leaders-buttons-container button {
	background-color: #91c94f;
	color: #fff;
	font-size: 1rem;
	width: 150px;
	height: 40px;
	text-align: center;
	margin: 20px 15px;
	transition: all 0.2s ease-in-out;
	font-weight: 600;
}

.leaders .content-container .leaders-buttons-container button:hover {
	background-color: #6bbd69;
}

@media only screen and (min-width: 768px) {
	.leaders .content-container {
		width: 70%;
		min-height: 291px;
		padding: 0 25px;
	}
	.leaders .content-container h1 {
		font-size: 3rem;
	}
}

@media only screen and (min-width: 1200px) {
	.leaders {
		padding: 0 0 177px 0;
	}
}
