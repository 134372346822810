.sl4 {
	background: linear-gradient(#f6e3e1 30%, #f3e0ed 70%, #e4efef) 100%;
	position: relative;
}

.sl4__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: -20%;
	left: 0;
}

.sl4__block {
	padding-top: 47px;
	padding-bottom: 30px;
	background: linear-gradient(rgba(246, 226, 226, 0.8), rgba(244, 225, 232, 0.8));
}

.sl4__block-btn {
	padding-top: 57px;
	padding-bottom: 160px;
	position: relative;
}

.sl4__single-flower {
	position: absolute;
	z-index: 3;
	top: 63%;
	right: 30%;
	width: auto;
	height: auto;
}
.sl4__flowers {
	position: absolute;
	top: -20px;
	right: -5%;
	width: auto;
	height: auto;
	overflow: visible;
}

.sl4__flowers-icon {
	opacity: 0;
	transition: 1s ease-in-out;
}
.sl4__flowers-icon.is-visible {
	opacity: 1;
}

.sl4-section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
	max-width: 910px;
}
.sl4-section-svg {
	position: absolute;
	right: -80px;
	bottom: -80px;
	z-index: 1;
}
.sl4-section-img {
	position: absolute;
	z-index: 2;
	right: 20px;
	bottom: 310px;
	max-width: 245px;
}

.sl4-section-img-2 {
	position: absolute;
	z-index: 2;
	right: 42px;
	bottom: 60px;
	max-width: 275px;
}

.sl4__block-bucket {
	position: relative;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl4__block-bucket-image {
	position: relative;
	margin: 15px 0 -15px 0;
	left: 13px;
	width: 100%;
	max-width: 324px;
}
.sl4__block-bucket-text {
	background: #fff;
	padding: 30px 40px 30px 34px;
	color: #5e3091;
	text-transform: uppercase;
	font-size: 1.125rem;
	margin: 0;
	line-height: 1.5;
}
.sl4__block-bucket-text span {
	font-weight: bold;
}

@media only screen and (min-width: 992px) {
	.sl4__block-btn {
		padding-top: 83px;
		padding-bottom: 99px;
	}

	.sl4__single-flower {
		top: 52%;
		right: 32%;
	}
	.sl4__flowers {
		top: -20px;
		right: -5%;
	}

	.sl4__block-bucket-text {
		font-size: 1.25rem;
	}
}
@media only screen and (min-width: 1200px) {
	.sl4__block-btn {
		padding-bottom: 420px;
	}

	.sl4__single-flower {
		top: 52%;
		right: 102px;
	}
	.sl4__flowers {
		top: 400px;
		right: 130%;
		transition: 5s ease-in-out;
		transform: rotate(20deg);
	}

	.sl4__flowers.animate {
		top: -67px;
		right: -21.5%;
		transform: rotate(0deg);
	}

	.sl4__block-bucket-text {
		width: 250px;
	}
	.sl4__block-content {
		display: flex;
		align-items: flex-start;
	}
	.sl4__block-column {
		width: 50%;
	}
	.sl4__block-column--left {
		min-width: 360px;
		margin: 0 50px 0 0;
	}
	.sl4__block-bucket-image {
		margin: 0 0 -15px 0;
	}
}
