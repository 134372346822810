@keyframes sway-flower-1 {
	0% {
		transform: translate(31.347px, 0) rotate(-5deg);
	}
	50% {
		transform: translate(31.347px, 0) rotate(15deg);
	}
	100% {
		transform: translate(31.347px, 0) rotate(-5deg);
	}
}
@keyframes sway-flower-2 {
	0% {
		transform: translate(0, 38.831px) rotate(8deg);
	}
	50% {
		transform: translate(0, 38.831px) rotate(20deg);
	}
	100% {
		transform: translate(0, 38.831px) rotate(9deg);
	}
}
@keyframes sway-flower-3 {
	0% {
		transform: translate(29.689px, 123.241px) rotate(-5deg);
	}
	50% {
		transform: translate(29.689px, 123.241px) rotate(-15deg);
	}
	100% {
		transform: translate(29.689px, 123.241px) rotate(-5deg);
	}
}

.sl3 {
	padding: 0 0 75px 0;
	position: relative;
	background: linear-gradient(#e8e4e3, #f6e3e1);
}

.sl3__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	bottom: 75px;
	left: 0;
}

.sl3__block {
	padding-top: 52px;
	padding-bottom: 70px;
	background: linear-gradient(rgba(235, 228, 224, 0.8), rgba(250, 230, 216, 0.8));
}
.sl3__block-content {
	margin: 0 0 100px 0;
}

.sl3__block-title {
	color: #a81f25;
}

.sl3-section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}
.sl3-section-img {
	position: absolute;
	z-index: 1;
	right: 88px;
	bottom: 40px;
	max-width: 260px;
}

.sl3__action {
	position: relative;
}
.sl3__flowers {
	position: absolute;
	bottom: -35px;
	right: -46px;
	width: auto;
	height: auto;
	z-index: 3;
	overflow: visible;
}
.sl3__flowers-icon-1 {
	animation: sway-flower-1 10s 0ms infinite normal ease-in-out;
}
.sl3__flowers-icon-2 {
	animation: sway-flower-2 10s 0ms infinite normal ease-in-out;
}
.sl3__flowers-icon-3 {
	animation: sway-flower-3 10s 0ms infinite normal ease-in-out;
}

.sl3__block-bucket {
	position: relative;
	font-family: 'brother-1816-printed', sans-serif;
}
.sl3__block-bucket-image {
	margin: 30px auto;
	width: 100%;
	max-width: 400px;
	display: block;
}
.sl3__block-bucket-text {
	background: #fff;
	padding: 30px 40px 30px 34px;
	color: #d33a29;
	text-transform: uppercase;
	font-size: 1.125rem;
	margin: 0;
	line-height: 1.5;
}
.sl3__block-bucket-text span {
	font-weight: bold;
}
@media only screen and (min-width: 480px) {
	.sl3__block-bucket-image {
		min-width: 325px;
	}
}
@media only screen and (min-width: 992px) {
	.sl3__block-bucket-text {
		font-size: 1.25rem;
	}
	.sl3-section-img {
		bottom: 80px;
	}
}

@media only screen and (min-width: 1200px) {
	.sl3 {
		padding: 0 0 214px 0;
	}
	.sl3__block {
		padding-bottom: 140px;
	}

	.sl3__block-bucket-text {
		width: 250px;
	}
	.sl3__block-content {
		display: flex;
		align-items: flex-end;
	}
	.sl3__block-column {
		width: 50%;
	}
	.sl3__block-column--left {
		min-width: 360px;
		margin: 0 50px 0 0;
	}
	.sl3__block-bucket-image {
		margin: 0 0 53px 0;
	}
}
