@keyframes float-down {
	0% {
		transform: translateY(-400px) translateX(-80px) rotate(65deg);
		opacity: 0;
	}
	25% {
		transform: translateY(-300px) translateX(-40px) rotate(55deg);
	}
	50% {
		transform: translateY(-200px) translateX(0px) rotate(45deg);
		opacity: 1;
	}
	75% {
		transform: translateY(-100px) translateX(40px) rotate(35deg);
	}
	100% {
		transform: translateY(0px) translateX(0) rotate(25deg);
	}
}

.narrative {
	padding: 100px 0 75px 0;
	position: relative;
	background: linear-gradient(#f7d970, #edeee7);
	left: 0;
	top: 0;
}

.narrative__background {
	width: 100%;
	height: auto;
	display: block;
	position: absolute;
	top: -50px;
	left: 0;
}

.narrative__top {
	width: 100%;
	height: auto;
	position: absolute;
	bottom: 100%;
	left: 0;
	margin: 0 0 -11% 0;
	z-index: 3;
}
.narrative__top-image {
	width: 100%;
	height: auto;
	display: block;
}

.narrative__block {
	background: linear-gradient(rgba(247, 217, 112, 0.8), rgba(252, 245, 227, 0.8));
	padding-top: 38px;
	padding-bottom: 135px;
}

.narrative__block-leaf {
	position: absolute;
	right: -155px;
	bottom: calc(100% - 110px);
	width: auto;
	height: auto;
	transform: rotate(25deg);
	opacity: 0;
}
.narrative__block-leaf.animate {
	animation: float-down 2000ms linear 0ms 1 normal forwards;
	opacity: 1;
}

.narrative__block-title {
	text-transform: uppercase;
	color: #6a4311;
	font-size: 1.688rem;
}
.narrative__block-message span {
	font-weight: bold;
}

.narrative__block-subtitle {
	text-transform: uppercase;
}

.narrative__block-bucket {
	position: relative;
	font-family: 'brother-1816-printed', sans-serif;
}
.narrative__block-bucket-image {
	position: relative;
	top: 15px;
	left: 13px;
	width: 100%;
	max-width: 290px;
}
.narrative__block-bucket-text {
	background: #fff;
	padding: 30px 33px 30px 34px;
	color: #da8824;
	text-transform: uppercase;
	font-size: 1.125rem;
	line-height: 1.417;
	margin: 0;
}
.narrative__block-bucket-text span {
	font-weight: bold;
}

.image-container {
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
}
.section-bg {
	position: absolute;
	right: 0px;
	bottom: 0px;
}
.section-img {
	position: absolute;
	z-index: 1;
	right: 70px;
	bottom: 30px;
	max-width: 260px;
}

.section-img-2 {
	position: absolute;
	z-index: 1;
	right: 70px;
	bottom: 350px;
	max-width: 165px;
}

.section-svg-1 {
	position: absolute;
	z-index: 2;
	right: 0px;
	bottom: 225px;
}
.section-svg-2 {
	position: absolute;
	z-index: 2;
	right: 15px;
	bottom: 0px;
}

.narrative__action {
	position: relative;
	margin: 126px 0 0 0;
}
.narrative__petals-left {
	position: absolute;
	top: -50px;
	left: 0;
	width: auto;
	height: auto;
	z-index: 3;
}

.narrative__petals-block {
	width: 265px;
	height: 410px;
	position: absolute;
	top: 0;
	left: 60%;
}
.narrative__petals-img {
	position: absolute;
	height: 30px;
	width: auto;
	display: block;
	opacity: 0;
	transition: 400ms;
}
.narrative__petals-img.is-active {
	opacity: 1;
}

.narrative__petals-img:nth-of-type(1) {
	top: 0;
	left: 60%;
}
.narrative__petals-img:nth-of-type(2) {
	top: 2%;
	left: 80%;
}
.narrative__petals-img:nth-of-type(3) {
	top: 30%;
	left: 80%;
	height: 20px;
}
.narrative__petals-img:nth-of-type(4) {
	top: 51%;
	left: 78%;
}
.narrative__petals-img:nth-of-type(5) {
	top: 51%;
	left: 100%;
}
.narrative__petals-img:nth-of-type(6) {
	top: 61%;
	left: 82%;
}
.narrative__petals-img:nth-of-type(7) {
	top: 70%;
	left: 72%;
}
.narrative__petals-img:nth-of-type(8) {
	top: 80%;
	left: 90%;
}
.narrative__petals-img:nth-of-type(9) {
	top: 82%;
	left: 75%;
}
.narrative__petals-img:nth-of-type(10) {
	top: 77%;
	left: 59%;
}
.narrative__petals-img:nth-of-type(11) {
	top: 80%;
	left: 49%;
}
.narrative__petals-img:nth-of-type(12) {
	top: 79%;
	left: 37%;
}
.narrative__petals-img:nth-of-type(13) {
	top: 88%;
	left: 45%;
}
.narrative__petals-img:nth-of-type(14) {
	top: 85%;
	left: 0%;
}
.narrative__petals-img:nth-of-type(15) {
	top: 100%;
	left: 33%;
}

@media only screen and (min-width: 992px) {
	.narrative {
		padding: 100px 0 217px;
	}
	.narrative__background {
		top: -75px;
	}
	.narrative__block-title {
		font-size: 2.25rem;
	}

	.narrative__block-bucket-text {
		font-size: 1.5rem;
	}
	.narrative__block-feature {
		display: flex;
		margin: 0 0 105px 0;
	}

	.narrative__petals-block {
		left: calc(100% - 30px);
		top: -62px;
	}
}
@media only screen and (min-width: 1200px) {
	.narrative {
		padding: 100px 0 317px 0;
	}
	.narrative__background {
		top: -140px;
	}
	.narrative__block-content {
		display: flex;
		align-items: flex-end;
	}
	.narrative__block-column {
		width: 50%;
	}
	.narrative__block-column--left {
		min-width: 340px;
		margin: 0 50px 0 0;
	}
	.narrative__block-bucket-text {
		width: 263px;
	}
}
