.footer {
	background-color: #2e2925;
	padding: 45px 0;
	position: relative;
	z-index: 9;
	padding: 15px 0px;
}

.footer-logo {
	margin: 0 auto 25px;
}

.footer-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-family: 'Source Sans Pro', sans-serif;
	max-width: 1093px;
	margin: 0 auto;
	justify-content: center;
	flex-wrap: wrap;
}
.footer-container h6 {
	color: #a7a7a7;
	font-size: 0.813rem;
	font-weight: 300;
	margin: 0 auto 25px;
	text-align: center;
}
.footer-container .links-container {
	display: flex;
	align-content: center;
	justify-content: space-around;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.footer-container .links-container a {
	border: 2px solid #d33c29;
	width: 155px;
	padding: 10px 0px;
	text-align: center;
	color: #fff;
	font-size: 0.875rem;
	font-weight: 400;
	transition: all 0.2s ease-in-out;
	margin: 10px;
}

.footer-container .links-container a:hover {
	background-color: #d33c29;
}

@media screen and (min-width: 480px) {
	.footer-container .links-container a {
		margin: 0;
	}
	.footer-container .links-container a:nth-of-type(1) {
		margin: 0 16px 0 0;
	}
}

@media screen and (min-width: 768px) {
	.footer {
		padding: 45px 0;
	}
}

@media screen and (min-width: 992px) {
	.footer-container {
		justify-content: space-evenly;
	}
	.footer-container h6 {
		margin: 0;
	}
	.footer-logo {
		margin: 0;
	}
}

@media screen and (min-width: 1200px) {
	.footer-container h6 {
		margin: 0 90px;
	}
}
