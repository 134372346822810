@import url('https://use.typekit.net/mio2xdd.css');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

/* Breakpoint references */
/* @media only screen and (min-width: 480px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {} */

html {
	font-size: 16px;
	font-family: 'Source Sans Pro', sans-serif;
	-webkit-text-size-adjust: 100%;
}

body {
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fff;
	padding: 0;
	margin: 0;
	color: #474747;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.headline {
	font-family: 'brother-1816-printed', sans-serif;
	margin: 0 0 25px 0;
}

.headline--1 {
	font-size: 1.5rem;
	line-height: 1.125;
	text-transform: uppercase;
}

a {
	text-decoration: none;
}

p {
	margin: 0;
}

.paragraph-text {
	font-size: 1rem;
	line-height: 1.5;
	margin: 0 0 10px 0;
}
.paragraph-text--italic {
	font-style: italic;
}
.container {
	max-width: 1280px;
	margin: 0 auto;
	width: calc(100% - 50px);
}

.container--fixed-tree {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 9;
	/* position: fixed;
    top: 0;
    right: 50%;
    height: 100%;
    z-index: 9;
    transform: translateX(50%); */
}
.container--modal {
	max-width: 1000px;
}
button {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	background: none;
	cursor: pointer;
}
.jump-link {
	position: relative;
	height: 0;
	width: 0;
	top: -100px;
}

.img-bkg {
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: cover;
	object-position: center center;
}
.img-bkg--contain {
	object-fit: contain;
}

.App {
	overflow-x: hidden;
}

.common-block {
	padding-right: 25px;
	padding-left: 25px;
	position: relative;
}
@media only screen and (min-width: 768px) {
	.common-block {
		max-width: 600px;
		margin: 0 0 0 28%;
		padding-right: 50px;
		padding-left: 50px;
	}
	.paragraph-text {
		font-size: 1.125rem;
	}
}

/* Tree fixed svg image */
.TreeSvg {
	display: block;
	width: auto;
	height: 100vh;
	position: relative;
	top: 0;
	left: -25%;
	z-index: 5;
	/* display: block;
	width: auto;
	height: 100%;
	position: absolute;
	top: 0;
	right: 446px;
	z-index: 5; */
}

@media only screen and (min-width: 1440px) {
	.TreeSvg {
		left: -35%;
	}
}

/* Our main content that lays on top of the tree lives within this element */
.main-layer {
	position: relative;
	z-index: 10;
}

.parallax {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	z-index: 1;
	display: none;
}
.parallax .parallax-inner {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transition: 0ms;
}
.parallax__img {
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.parallax__img--lfol {
	width: 142%;
	left: -9%;
	/* max-width: 1820px; */
	/* max-height: 2010px; */
}
.parallax__img--narrative {
	width: 114%;
	left: -11%;
	/* max-width: 1455px; */
}
.parallax__img--sl2 {
	width: 128%;
	left: -30%;
	/* max-width: 1637px; */
}
.parallax__img--sl3 {
	width: 146%;
	left: -29%;
	/* max-width: 1875px; */
}
.parallax__img--sl4 {
	width: 109%;
	left: -2%;
	/* max-width: 1397px; */
}

.parallax__img--sl5 {
	width: 139%;
	left: -16%;
	/* max-width: 1780px; */
}

.parallax__img--news {
	width: 123%;
	left: -6.5%;
	/* max-width: 1573px; */
}

@media only screen and (min-width: 768px) {
	.parallax {
		display: block;
	}
}
